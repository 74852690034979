import './instrument-client.tsx';
//
import React from 'react';
import ReactDOM from 'react-dom/client';

import { RouterProvider } from '~/components/RouterProvider.tsx';
import { setupBrowserChat } from '~/core/chat/setupBrowserChat.ts';
import { AppProps, RootDataProvider } from '~/html-template.tsx';

const props: AppProps = {
  clientRef: {},
  ssrMethodsContext: null,
  initialState: window.INSTATE,
};

ReactDOM.hydrateRoot(
  document.getElementById('root')!,
  <RootDataProvider rootProps={props}>
    <RouterProvider url={window.location.href} />
  </RootDataProvider>,
);

setupBrowserChat().catch(console.error);
