import { styled } from '@linaria/react';
import { cx } from '@zazcart/commons';
import Big from 'big.js';
import React, { useMemo } from 'react';
import { FormattedNumberParts } from 'react-intl';
import { Money } from '~/lib/shopify/types.ts';

const PriceWrapper = styled.span`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  line-height: 1;
  font-size: var(--font-size-500);
  color: var(--color-text-default);
  user-select: all;
  span {
    display: inline-flex;
  }

  .price-part {
    display: inline-flex;
    &.decimal {
      opacity: 0.8;
      transform: scale(0.8);
      transform-origin: center;
    }
  }

  &:not(.flat) .price-part {
    &.fraction {
      margin-left: 1px;
      font-size: 70%;
    }

    &.currency {
      margin-top: 4px;
      font-size: 70%;
    }
  }
`;

export type PriceProps = {
  className?: string;
  flat?: boolean;
  value: Money;
} & React.ComponentProps<'span'>;

export function Price({ className, value, flat, ...rest }: PriceProps) {
  if (!value?.currencyCode || !value?.amount) {
    console.log(`price received an invalid value`, value);
    return '-';
  }

  const { amount: maybeBig, currencyCode } = value;

  const amount = useMemo(() => {
    return Big(maybeBig).div(100).toNumber();
  }, [maybeBig]);

  return (
    <PriceWrapper
      itemProp="offers"
      itemScope
      itemType="https://schema.org/Offer"
      className={cx(className, 'Price', { flat })}
      {...rest}
    >
      <meta itemProp="priceCurrency" content={currencyCode} />
      <span itemProp="price" content={value.amount.toString()}>
        <FormattedNumberParts
          value={amount}
          style="currency"
          currency={value.currencyCode}
          currencyDisplay="narrowSymbol"
        >
          {(parts) => (
            <>
              {parts
                .filter((el) => el.value.trim())
                .map((part, i) => {
                  let className = `price-part ${part.type}`;
                  if (part.type === 'decimal') {
                    return (
                      <span key={i} className={className}>
                        ,
                      </span>
                    );
                  }

                  return (
                    <span key={i} className={className}>
                      {part.value}
                    </span>
                  );
                })}
            </>
          )}
        </FormattedNumberParts>
      </span>
    </PriceWrapper>
  );
}
