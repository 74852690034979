export type MouseEvent = {
  metaKey: boolean; // Command key (Mac) or Windows key
  ctrlKey: boolean; // Control key
  button?: number; // Mouse button number
};

export function isNewTabIntent(event: MouseEvent): boolean {
  if (
    !event ||
    typeof event !== 'object' ||
    !('metaKey' in event && 'ctrlKey' in event)
  ) {
    return false;
  }

  // macOS: Command key
  // Windows/Linux: Control key
  // Or middle mouse button click
  return (
    event.metaKey ||
    event.ctrlKey ||
    (typeof event.button === 'number' && event.button === 1)
  );
}
