import { styled } from '@linaria/react';
import { cx, DivProps } from '@zazcart/commons';
import * as React from 'react';
import { JSX } from 'react';
import { ProductImage } from '~/lib/shopify/types.ts';

export type ImageLoaderProps = Omit<JSX.IntrinsicElements['img'], 'src'> & {
  className?: string;
  fit: 'cover' | 'contain' | 'scale-down' | 'initial' | 'none';
  wrapperProps?: DivProps;
} & (
    | {
        src?: undefined;
        image: ProductImage | null | undefined;
      }
    | { src: string; image?: undefined }
  );

const Wrapper = styled.div`
  --object-fit: cover;
  display: inline-flex;
  background: rgba(127, 127, 127, 0.11);
  position: relative;
  background-size: var(--object-fit);
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: var(--object-fit);
    object-position: center;
    animation: blurIn 500ms ease-in;
    background: transparent;
    z-index: 1;
    display: block;
    position: absolute;
    border: none;
  }
`;

export function ImageLoader(props: ImageLoaderProps) {
  const { className, alt = '', image, wrapperProps, fit, ...others } = props;

  const { width = '100%', height = '100%' } = props;

  const src = image?.url || props.src;

  return (
    <Wrapper
      className={cx('ImageLoader img', wrapperProps?.className, className)}
      style={{
        width,
        height,
        // @ts-expect-error
        '--object-fit': fit,
      }}
    >
      <img alt={alt} src={src} {...others} className={cx('img', className)} />
    </Wrapper>
  );
}
