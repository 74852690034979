import { styled } from '@linaria/react';
import { Heading, Icon } from '@zazcart/ui';
import React, { PropsWithChildren } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: var(--gap);
  padding: var(--gap);
`;

export class ErrorBoundary extends React.Component<PropsWithChildren> {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }
  componentDidCatch(_error: any, info: any) {
    console.warn('componentDidCatch', info.componentStack);
  }
  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <Heading level={4}>Error </Heading>
          <Icon icon={'alert'} size={'lg'} accessibilityLabel={'Erro'} />
        </Wrapper>
      );
    }
    return this.props.children;
  }
}
