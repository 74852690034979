import './Form.scss';

import {
  DebugFunction,
  FormApi,
  FormState,
  FormSubscriber,
  FormSubscription,
  Mutator,
  SubmissionErrors,
  ValidationErrors,
} from 'final-form';

import { connectReduxDevTools } from '@zazcart/commons';
import * as React from 'react';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  Form as FormBase,
  FormRenderProps,
  FormSpy,
  FormSpyProps,
  FormSpyRenderProps,
  useField,
  useForm,
  useFormState,
} from 'react-final-form';
import { FormProvider } from '~/ariakit.tsx';
import { ElementProps, MergeProps, VariantProps, variantsProps } from '~/utils';

export type {
  DebugFunction,
  FormApi,
  FormRenderProps,
  FormSpyProps,
  FormSpyRenderProps,
  FormSubscriber,
  Mutator,
  SubmissionErrors,
  ValidationErrors,
};

export { FormSpy, useField, useForm, useFormState };

export type FormValuesDefault = Record<string, any>;

export type FormProps<
  FormValues = FormValuesDefault,
  InitialFormValues = Partial<FormValues>,
> = MergeProps<
  ElementProps<VariantProps, 'form'>,
  {
    name: string;
    form?: FormApi<FormValues, InitialFormValues>;
    debug?: DebugFunction<FormValues, InitialFormValues>;
    destroyOnUnregister?: boolean;
    initialValues?: InitialFormValues;
    keepDirtyOnReinitialize?: boolean;
    mutators?: { [key: string]: Mutator<FormValues, InitialFormValues> };
    onSubmit: (
      values: FormValues,
      form: FormApi<FormValues, InitialFormValues>,
    ) => SubmissionErrors | Promise<SubmissionErrors> | void;
    validate?: (
      values: FormValues,
    ) => ValidationErrors | Promise<ValidationErrors>;
    validateOnBlur?: boolean;
    children:
      | ReactNode
      | ((
          formApi: FormRenderProps<FormValues, InitialFormValues>,
        ) => ReactNode);
  }
>;

export function Form<
  FormValues = Record<string, any>,
  InitialFormValues = Partial<FormValues>,
>(props: FormProps<FormValues, InitialFormValues>): ReactNode;

export function Form(props: FormProps) {
  const {
    debug,
    destroyOnUnregister,
    onSubmit,
    initialValues,
    keepDirtyOnReinitialize,
    mutators,
    validate,
    validateOnBlur = true,
    children,
    form,
    ...otherProps
  } = props;

  const [loading, setLoading] = useState(false);

  return (
    <FormBase
      {...{
        debug,
        destroyOnUnregister,
        initialValues,
        keepDirtyOnReinitialize,
        mutators,
        validate,
        validateOnBlur,
      }}
      form={form}
      onSubmit={async (values, form) => {
        setLoading(true);
        try {
          await onSubmit?.(values, form);
        } finally {
          setLoading(false);
        }
      }}
      render={(renderApi) => {
        const { handleSubmit, form } = renderApi;

        return (
          <FormProvider>
            <form
              onSubmit={(ev) => {
                const fields = form.getRegisteredFields();

                const firstErr = fields.find(
                  (el) => form.getFieldState(el)?.error,
                );

                if (firstErr) {
                  const first =
                    ev.currentTarget.querySelector<HTMLInputElement>(
                      `[name="${firstErr}"]`,
                    );
                  first?.focus?.();
                  first?.setAttribute?.('aria-invalid', 'true');
                }

                handleSubmit(ev);
              }}
              {...variantsProps('Form', otherProps, {
                loading,
                FormLoading: loading,
              })}
            >
              <FormDevTools name={props.name} />
              {typeof children === 'function' ? children(renderApi) : children}
            </form>
          </FormProvider>
        );
      }}
    />
  );
}

export function FormReader<Values extends FormValuesDefault, Value>(
  props: {
    get(values: Values, state: FormState<Values>, form: FormApi<Values>): Value;
    onChange?(
      value: Value,
      state: FormState<Values>,
      form: FormApi<Values>,
    ): void;
    children?(value: Value, api: FormApi<Values>): ReactNode;
  } & FormSubscription,
) {
  const { children, get, ...subscription } = props;
  const form = useForm<Values>();

  const [state, setState] = useState(() => {
    const state = form.getState();
    return get?.(state.values, state, form);
  });

  const valueRef = useRef(state);

  useEffect(() => {
    const { get } = props;
    if (!get) return;
    return form.subscribe(
      (state) => {
        const got = get(state.values, state, form);
        if (JSON.stringify(valueRef.current) === JSON.stringify(got)) return;
        valueRef.current = got;
        props.onChange?.(got, state, form);
        setState(got);
      },
      { values: true, ...subscription },
    );
  }, [form, !!subscription, !!get]);

  return children?.(state, form);
}

export type FormDevToolProps = { name: string };

export function FormDevTools(props: FormDevToolProps) {
  const { name } = props;

  const devTools = useMemo(() => {
    return connectReduxDevTools({ field: `form/${name}`, initialState: {} });
  }, [name]);

  return (
    <FormReader
      get={(_, { values, errors, valid }) => {
        return {
          values,
          errors,
          valid,
        };
      }}
      onChange={(value) => {
        devTools?.send({ type: 'change' }, value);
      }}
    />
  );
}
