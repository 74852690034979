import './app-footer.scss';

import { cx } from '@zazcart/commons';
import { Link } from '@zazcart/ui';
import * as React from 'react';

export type AppFooterProps = {
  className?: string;
};

const year = new Date().getFullYear();

export function AppFooter(props: AppFooterProps) {
  const { className } = props;

  return (
    <footer className={cx('AppFooter', className)}>
      <span className="copyright">© {year} ZAZCART LTDA</span>
      <div className="footer-links">
        <Link accessibilityLabel={'Sobre'} href={'/terms'}>
          Sobre
        </Link>

        <Link accessibilityLabel={'Termos'} href={'/terms'}>
          Termos
        </Link>

        <a
          aria-label={'Instagram zazcart'}
          href={'https://www.instagram.com/zazcart'}
          target={'_blank'}
        >
          @zazcart
        </a>
      </div>
    </footer>
  );
}
