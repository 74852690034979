import './CartFooterActions.scss';

import { cx, ElementProps } from '@zazcart/commons';
import { Button } from '@zazcart/ui';
import * as React from 'react';
import { useShoppingCart } from '~/state';

export type CartFooterActionsProps = ElementProps<{}, 'div'>;

export function CartFooterActions(props: CartFooterActionsProps) {
  const { className } = props;
  const { canCheckout, setOpen } = useShoppingCart();

  return (
    <div className={cx('CartFooterActions', className)}>
      <Button
        href={'/checkout'}
        primary
        fullWidth
        accessibilityLabel="Checkout"
        disabled={!canCheckout}
        onClick={() => {
          setOpen(false);
        }}
      >
        Checkout
      </Button>
    </div>
  );
}
