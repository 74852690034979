import IMask, {
  type FactoryOpts,
  type InputMask as IMaskInstanceType,
} from 'imask';

import type { FactoryStaticOpts } from 'imask/masked/factory';
import type { MutableRefObject } from 'react';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';

export type MaskOptions = FactoryStaticOpts; // can be more types, but not used (yet?)
export type MaskDefinitionObject = FactoryOpts;
export type MaskDefinition = MaskDefinitionObject | string;

export type InputMask = IMaskInstanceType<MaskOptions>;

export type UseMaskConfig = {
  initialValue?: string;
  ref?: MutableRefObject<HTMLInputElement | null>;
  onAccept?: (
    value: InputMask['value'],
    maskRef: InputMask,
    e?: InputEvent,
  ) => void;
  onComplete?: (
    value: InputMask['value'],
    maskRef: InputMask,
    e?: InputEvent,
  ) => void;
  defaultValue?: InputMask['value'];
  defaultUnmaskedValue?: InputMask['unmaskedValue'];
  defaultTypedValue?: InputMask['typedValue'];
};

export type UseMaskResult = {
  ref: MutableRefObject<HTMLInputElement | null>;
  maskRef: MutableRefObject<InputMask | null>;
  value: InputMask['value'];
  setValue: Dispatch<InputMask['value']>;
  unmaskedValue: InputMask['unmaskedValue'];
  setUnmaskedValue: Dispatch<InputMask['unmaskedValue']>;
  typedValue: InputMask['typedValue'];
  setTypedValue: Dispatch<InputMask['typedValue']>;
};

export function useMask(
  definition: MaskDefinition | string | undefined,
  config: UseMaskConfig = {},
): UseMaskResult {
  const {
    onAccept,
    onComplete,
    ref = useRef<HTMLInputElement | null>(null),
    defaultValue,
    defaultUnmaskedValue,
    defaultTypedValue,
    initialValue,
  } = config;

  const opts: FactoryOpts = (() => {
    if (!definition) return {};
    return typeof definition === 'string' ? { mask: definition } : definition;
  })();

  const maskRef = useRef<InputMask | null>(null);

  const [lastAcceptState, setLastAcceptState] = useState<{
    value?: InputMask['value'];
    unmaskedValue?: InputMask['unmaskedValue'];
    typedValue?: InputMask['typedValue'];
  }>({});

  const [value, setValue] = useState<InputMask['value']>(initialValue || '');
  const [unmaskedValue, setUnmaskedValue] =
    useState<InputMask['unmaskedValue']>('');
  const [typedValue, setTypedValue] = useState<InputMask['typedValue']>();

  const _destroyMask = useCallback(() => {
    maskRef.current?.destroy?.();
    maskRef.current = null;
  }, []);

  const storeLastAcceptedValues = useCallback(() => {
    const m = maskRef.current;
    if (!m) return;

    setLastAcceptState({
      value: m.value,
      unmaskedValue: m.unmaskedValue,
      typedValue: m.typedValue,
    });

    setTypedValue(m.typedValue);
    setUnmaskedValue(m.unmaskedValue);
    setValue(m.value);
  }, []);

  const _onAccept = useCallback(
    (event?: InputEvent) => {
      const m = maskRef.current;
      if (!m) return;

      storeLastAcceptedValues();

      onAccept?.(m.value, m, event);
    },
    [onAccept],
  );

  const _onComplete = useCallback(
    (event?: InputEvent) =>
      maskRef.current &&
      onComplete?.(maskRef.current.value, maskRef.current, event),
    [onComplete],
  );

  useEffect(() => {
    const { value: lastAcceptValue, ...state } = lastAcceptState;
    const mask = maskRef.current;

    if (!mask || value === undefined) return;

    if (lastAcceptValue !== value) {
      mask.value = value;
      if (mask.value !== value) _onAccept();
    }
    setLastAcceptState(state);
  }, [value]);

  useEffect(() => {
    const { unmaskedValue: lastAcceptUnmaskedValue, ...state } =
      lastAcceptState;
    const mask = maskRef.current;

    if (!mask || unmaskedValue === undefined) return;

    if (lastAcceptUnmaskedValue !== unmaskedValue) {
      mask.unmaskedValue = unmaskedValue;
      if (mask.unmaskedValue !== unmaskedValue) _onAccept();
    }
    setLastAcceptState(state);
  }, [unmaskedValue]);

  useEffect(() => {
    const { typedValue: lastAcceptTypedValue, ...state } = lastAcceptState;
    const mask = maskRef.current;

    if (!mask || typedValue === undefined) return;

    if (lastAcceptTypedValue !== typedValue) {
      mask.typedValue = typedValue;
      if (!mask.masked.typedValueEquals(typedValue)) _onAccept();
    }
    setLastAcceptState(state);
  }, [typedValue]);

  useEffect(() => {
    const el = ref.current;

    if (!el || !opts?.mask) return _destroyMask();

    const mask = maskRef.current;

    if (!mask) {
      if (el && opts?.mask) {
        maskRef.current = IMask(el, opts) as InputMask;
        storeLastAcceptedValues();

        if (defaultValue !== undefined) setValue(defaultValue);
        if (defaultUnmaskedValue !== undefined)
          setUnmaskedValue(defaultUnmaskedValue);
        if (defaultTypedValue !== undefined) setTypedValue(defaultTypedValue);
      }
    } else {
      mask?.updateOptions(opts as any); // TODO fix no idea
    }
  }, [opts, _destroyMask, _onAccept]);

  useEffect(() => {
    if (!maskRef.current) return;

    const mask = maskRef.current;

    mask.on('accept', _onAccept);
    mask.on('complete', _onComplete);

    return () => {
      mask.off('accept', _onAccept);
      mask.off('complete', _onComplete);
    };
  }, [_onAccept, _onComplete]);

  useEffect(() => _destroyMask, [_destroyMask]);

  return {
    ref,
    maskRef,
    value,
    setValue,
    unmaskedValue,
    setUnmaskedValue,
    typedValue,
    setTypedValue,
  };
}
