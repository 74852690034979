import { cx } from '@zazcart/commons';
import React from 'react';
import { useHeaderScroll } from '~/components/app-header/use-header-scroll.tsx';
import { CartButton } from '~/components/cart-button.tsx';
import { AppSearch } from './app-search.tsx';

export function AppHeaderDesk({ className }: { className?: string }) {
  const { ref } = useHeaderScroll();

  return (
    <header className={cx('app-header header-desk', className)} ref={ref}>
      <div className="header-container">
        <div className="header-center">
          <AppSearch />
        </div>

        <div className="header-right">
          <CartButton />
        </div>
      </div>
    </header>
  );
}
