import type {
  InfiniteData,
  UseSuspenseInfiniteQueryResult,
} from '@tanstack/react-query';
import {
  useQueryClient,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { IS_BROWSER } from 'powership';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { SSRMethodContextProvider } from '~/state';
import {
  MethodArgs,
  MethodName,
  MethodResult,
  Methods,
  PaginatedMethodNames,
} from './interfaces.ts';
import { runMethod } from './runMethod.ts';

export type UseMethodResult<Method extends MethodName> = UseSuspenseQueryResult<
  MethodResult<Method>
> & {
  setArgs: (action: Partial<MethodArgs<Method>>) => void;
};

export type UseMethodOptions<Method extends MethodName> = {
  queryKey?: any[];
  transform?(data: MethodResult<Method>): unknown;
  loadOnMount?: boolean;
};

// TODO conditional fetch
export function useMethod<Method extends MethodName>(
  method: Method,
  initialArgs: MethodArgs<Method>,
  options: UseMethodOptions<Method> = {},
): UseMethodResult<Method> {
  const { queryKey, refetchOnMount, setArgs, queryFn } = usePrepareMethod({
    method,
    initialArgs,
    options,
  });

  const state = useSuspenseQuery<Methods[Method]['output']>({
    refetchOnMount,
    queryKey,
    queryFn,
  });

  return {
    setArgs,
    ...state,
  };
}

export type MethodPaginatedResult<Method extends PaginatedMethodNames> =
  UseSuspenseInfiniteQueryResult<
    InfiniteData<MethodResult<Method>, unknown>,
    Error
  > & {
    setArgs(args: Partial<MethodArgs<Method>>): void;
  };

export function useMethodPaginated<Method extends PaginatedMethodNames>(
  method: Method,
  initialArgs: Methods[Method]['input'],
  options?: UseMethodOptions<Method>,
): MethodPaginatedResult<Method> {
  const { args, queryKey, refetchOnMount, setArgs, context, onResult } =
    usePrepareMethod({
      method,
      initialArgs,
      options,
    });

  const result = useSuspenseInfiniteQuery<MethodResult<Method>>({
    refetchOnMount,
    queryKey,
    initialPageParam: (initialArgs as { after: string }).after,
    async queryFn({ pageParam }) {
      const data = await runMethod({
        method,
        args: { ...args, after: pageParam },
        context: context!,
      });
      if (options?.transform) {
        return options.transform(data) as MethodResult<Method>;
      }
      return data;
    },
    getNextPageParam(_, allPages): string | undefined {
      // @ts-ignore
      return allPages[allPages.length - 1]?.pageInfo?.endCursor;
    },
    getPreviousPageParam(_, allPages): string | undefined {
      // @ts-ignore
      return allPages[allPages.length - 1]?.pageInfo?.startCursor;
    },
  });

  onResult(result);

  return {
    ...result,
    setArgs,
  };
}

export function useMethodClient<Method extends MethodName>(
  method: Method,
  options?: {
    ignoreCache?: boolean; // default to true
  },
) {
  const context = useContext(SSRMethodContextProvider);

  const [data, setData] = useState<MethodResult<Method>>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const { ignoreCache = true } = options || {};

  return {
    data,
    error,
    loading,
    async exec(
      args: MethodArgs<Method>,
      options?: {
        key?: any;
        transform?(data: MethodResult<Method>): unknown;
      },
    ) {
      setLoading(true);
      const result = await queryClient
        .fetchQuery({
          queryKey: [
            options?.key,
            args,
            ignoreCache ? Math.ceil(Date.now() / 1000) : 0,
          ],
          async queryFn() {
            const data = await runMethod({ method, args, context: context! });
            if (options?.transform) {
              return options.transform(data) as MethodResult<Method>;
            }
            return data;
          },
        })
        .catch((error) => {
          setError(error.message || error.toString());
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });

      setData(result);
      return result;
    },
  };
}

export function usePrepareMethod<Method extends MethodName>(params: {
  method: Method;
  initialArgs: MethodArgs<Method> | (() => MethodArgs<Method>);
  options: UseMethodOptions<Method> | undefined;
}) {
  const { options = {}, method } = params;
  const { loadOnMount = true, queryKey } = options;

  const queryClient = useQueryClient();

  const initialArgs = useMemo(() => {
    if (typeof params.initialArgs === 'function') {
      return params.initialArgs();
    }
    return params.initialArgs;
  }, []);

  const [, setTriggerUpdate] = useState(0);
  const argsRef = useRef(initialArgs);

  const memoizedArgs = useMemo(() => {
    if (JSON.stringify(argsRef.current) !== JSON.stringify(initialArgs)) {
      argsRef.current = initialArgs;
      setTriggerUpdate((prev) => prev + 1);
    }
    return argsRef.current;
  }, [initialArgs]);

  const context = useContext(SSRMethodContextProvider);

  const setArgs = useCallback(
    (partial: Partial<MethodArgs<Method>>) => {
      argsRef.current = { ...argsRef.current, ...partial };
      setTriggerUpdate((prev) => prev + 1);
    },
    [setTriggerUpdate, argsRef],
  );

  async function queryFn(): Promise<any> {
    const data = await runMethod({
      method,
      args: memoizedArgs,
      context: context!,
    });
    if (options?.transform) {
      return options.transform(data) as MethodResult<Method>;
    }
    return data;
  }

  return {
    onResult(result: unknown) {
      if (!IS_BROWSER) return;
      const cache = findItemsToCache(result, null);
      cache?.forEach((el) => {
        queryClient.setQueryData([el.value], el.item);
      });
    },
    queryFn,
    args: memoizedArgs,
    context,
    queryKey: queryKey || [method, memoizedArgs],
    refetchOnMount: loadOnMount,
    setArgs,
  };
}

const cacheableKeys = new Set(['handle']);

type CacheItem = { field: string; value: string; item: any };

function findItemsToCache(
  input: unknown,
  seen: Set<string> | null,
): CacheItem[] | null {
  if (!input || typeof input !== 'object') return null;

  seen = seen || new Set<string>();
  const items: CacheItem[] = [];

  if (Array.isArray(input)) {
    input.forEach((el) => {
      const next = findItemsToCache(el, seen);
      if (!next) return;
      items.push(...next);
    });
    return items;
  }

  let added = false;

  cacheableKeys.forEach((field) => {
    const value = (input as any)[field];
    if (typeof value === 'string') {
      added = true;
      const seenKey = `${field}_${value}`;
      if (seen.has(seenKey)) return;
      seen.add(seenKey);
      items.push({
        field,
        value,
        item: input,
      });
    }
  });

  if (added) return items;

  Object.entries(input).forEach(([field, value]) => {
    if (added) return;
    const seenKey = `${field}_${value}`;
    if (seen.has(seenKey)) return;

    if (typeof value === 'string' && cacheableKeys.has(field)) {
      added = true;
      // here we push items
      seen.add(seenKey);
      items.push({
        field,
        value,
        item: input,
      });
    } else {
      let next = findItemsToCache(value, seen);
      if (!next) return;
      items.push(...next);
    }
  });

  return items;
}
