import type { CartItemSnapshot } from '~/entity/cart.ts';
import { productLink } from '~/lib/productLink.ts';
import { Product } from '~/lib/shopify/types.ts';
import { CartStateItem } from '~/state';

export type ParseCartItemSnapshotConfig = {
  product: Product;
  cartItem: CartStateItem | null; // TODO, make it required (it is null on <ProductCard />)
};

export function parseCartItemSnapshot(
  config: ParseCartItemSnapshotConfig,
): CartItemSnapshot {
  const { product, cartItem } = config;

  const link = productLink(product);

  const image = cartItem ? cartItem.image : product.featuredImage;

  const price = cartItem
    ? cartItem.variantTotalPrice
    : product.priceRange.minVariantPrice;

  const variantTitle = cartItem
    ? cartItem.fullTitle
    : `${product.seo.title || product.title || ''}`;

  let quantity = +(cartItem?.quantity || '0');

  return {
    id: cartItem?.id || '',
    quantity,
    variantTitle,
    productTitle: product.title,
    price: {
      amount: price.amount.toString(),
      currencyCode: price.currencyCode,
    },
    image,
    link,
    selectedOptions: cartItem?.selectedOptions || [],
  };
}
