import {
  ElementProps,
  isNewTabIntent,
  useNavigate,
} from '@zazcart/commons';
import * as React from 'react';
import { MergeProps, variantsProps } from '~/utils';
import { VariantProps } from '~/variants.tsx';

export type BaseLinkProps = Pick<
  ElementProps<{}, 'a'>,
  | 'onClick'
  | 'aria-label'
  | 'onBlur'
  | 'rel'
  | 'href'
  | 'className'
  | 'id'
  | 'title'
  | 'children'
  | 'role'
>;

export type LinkProps = MergeProps<
  VariantProps,
  BaseLinkProps,
  {
    preload?: boolean;
    accessibilityLabel: string;
    as?:
      | 'a'
      | 'button'
      | ((props: BaseLinkProps & { [k: string]: any }) => React.ReactNode);
  }
>;

export const Link = React.forwardRef<HTMLLinkElement, LinkProps>(function Link(
  props: LinkProps,
  ref,
) {
  const {
    onClick,
    preload = true,
    as = 'a',
    href,
    rel = as === 'a' ? (preload ? 'preload' : undefined) : undefined,
    accessibilityLabel,
    ...otherProps
  } = props;

  const navigate = useNavigate();

  return React.createElement(as, {
    ...variantsProps(
      'Link',
      otherProps,
      { Button: as === 'button' },
    ),
    'aria-label': accessibilityLabel,
    href,
    rel,
    ref,
    onClick(ev) {
      if (isNewTabIntent(ev)) return;
      onClick?.(ev);
      if (ev.defaultPrevented) return;
      ev.preventDefault();
      if (!href || href === '#') return;
      navigate(href);
    },
  });
});
