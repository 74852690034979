import { IS_BROWSER } from 'powership';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useMethod, useMethodClient } from '~/core/use-method.tsx';
import { Preferences } from '~/methods/preferences.ts';

export type PreferencesContextValue = Partial<Preferences>;
export const PreferencesContext = React.createContext<PreferencesContextValue>(
  {},
);

export function usePreferences(): PreferencesContextValue {
  return React.useContext(PreferencesContext);
}

export function PreferencesProvider(props: PropsWithChildren) {
  const { children } = props;

  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  const systemTheme = IS_BROWSER ? (prefersDark ? 'dark' : 'light') : undefined;

  const {
    data: { preferences },
  } = useMethod('getPreferences', {});

  const methodClient = useMethodClient('upsertPreferences');

  useEffect(() => {
    if (systemTheme && preferences.theme !== systemTheme) {
      methodClient.exec({ theme: systemTheme }).catch(console.error);
    }
  }, [systemTheme]);

  const value = useMemo<PreferencesContextValue>(() => {
    const theme = systemTheme || preferences?.theme || 'light';
    return { theme };
  }, [systemTheme, preferences]);

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
}
