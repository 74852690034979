import './Heading.scss';

import React from 'react';
import { LineClamp } from '~/LineClamp.tsx';
import { ElementProps, VariantProps, variantsProps } from '~/utils';

export type HeadingProps = ElementProps<
  VariantProps & {
    lineClamp?: number;
    text?: string;
    level: 1 | 2 | 3 | 4 | 5;
  },
  'h1'
>;

export function Heading(props: HeadingProps) {
  const { lineClamp, level, text, ...otherProps } = props;

  return React.createElement(
    `h${level}`,
    variantsProps('Heading', otherProps),
    (() => {
      if (typeof text === 'string' && lineClamp) {
        return <LineClamp lines={lineClamp}>{text}</LineClamp>;
      }
      if (typeof props.children === 'string' && lineClamp) {
        return <LineClamp lines={lineClamp}>{props.children}</LineClamp>;
      }
      return props.children;
    })(),
  );
}
