import '~/css/index.scss';

import {
  hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { PRODUCTION, UserAgent } from '@zazcart/commons';
import { DeviceTypeProvider, UIProvider } from '@zazcart/ui';
import { IS_BROWSER, ms } from 'powership';
import { PropsWithChildren, default as React, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { IsomorphicAppConfig } from '~/app-config.tsx';
import { PreferencesProvider } from '~/components/PreferencesProvider.tsx';
import { MethodContext } from '~/core/method-context.ts';
import { AccountLoginState, AccountProvider } from '~/lib/accounts/ui';
import { StateProvider } from '~/state';

export type INSTATE = {
  url: string;
  cookies: { [K: string]: any };
  userAgent: UserAgent | undefined;
  accountState: AccountLoginState;
  queryState?: object | undefined;
  appConfig: IsomorphicAppConfig;
  deviceType: 'desktop' | 'mobile';
};

export type ManifestAssets = {
  js: string[];
  css: string[];
};

export type AppProps = {
  ssrMethodsContext: MethodContext | null; // only ssr
  initialState: INSTATE;
  clientRef: { current?: QueryClient };
};

const RootPropsContext = React.createContext(null as unknown as AppProps);

const TWO_MINUTES = ms('2min');

// This wrapper should only have providers,
// as expected by tanstack router Wrapper config.
// We use the HTMLTemplate as the root, and it is used in __root.tsx
export function RootDataProvider(
  props: PropsWithChildren<{ rootProps: AppProps }>,
) {
  const { children, rootProps } = props;
  const { initialState, ssrMethodsContext, clientRef } = rootProps;

  const [queryClient] = useState(() => {
    if (clientRef.current) return clientRef.current;
    const client = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: TWO_MINUTES,
        },
      },
    });
    if (IS_BROWSER) {
      hydrate(client, window.DATA_LOADERS);
    }
    return (clientRef.current = client);
  });

  return (
    <UIProvider>
      <RootPropsContext.Provider value={rootProps}>
        <QueryClientProvider client={queryClient}>
          <CookiesProvider
            defaultSetOptions={ssrMethodsContext?.cookieOptions}
            allCookies={initialState.cookies}
          >
            <DeviceTypeProvider deviceType={initialState.deviceType}>
              <IntlProvider locale="pt-BR" defaultLocale="pt-BR">
                <StateProvider ssrMethodsContext={ssrMethodsContext}>
                  <AccountProvider
                    initialLoginState={initialState.accountState}
                  >
                    <PreferencesProvider>
                      <>{children}</>
                    </PreferencesProvider>
                  </AccountProvider>
                </StateProvider>
              </IntlProvider>
            </DeviceTypeProvider>
          </CookiesProvider>
        </QueryClientProvider>
      </RootPropsContext.Provider>
    </UIProvider>
  );
}

export function HTMLTemplate(props: PropsWithChildren<AppProps>) {
  const { children } = props;

  const [stateScript] = useState(() => {
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: `window.INSTATE=${JSON.stringify(props.initialState)}`,
        }}
      />
    );
  });

  return (
    <>
      <div id="root">{children}</div>

      {stateScript}

      <DevScriptsBody />
    </>
  );
}

export function HeadTemplate(props: {
  productionAssets: ManifestAssets | null | undefined;
}) {
  const { productionAssets } = props;

  return (
    <>
      <head>
        {PRODUCTION && (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=AW-16796698888"
            />
            <script
              dangerouslySetInnerHTML={{
                __html:
                  '  window.dataLayer = window.dataLayer || [];' +
                  '  function gtag(){dataLayer.push(arguments);}' +
                  "  gtag('js', new Date());" +
                  "  gtag('config', 'AW-16796698888');",
              }}
            />
          </>
        )}

        <meta charSet="UTF-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />

        {iconsElement}

        {productionAssets?.css?.map((cssFile) => (
          <link
            key={cssFile}
            rel="stylesheet"
            /*@ts-ignore*/
            crossOrigin
            href={`/${cssFile}`}
          />
        ))}

        {productionAssets?.js?.map((js) => (
          <script
            /*@ts-ignore*/
            crossOrigin
            key={js}
            type="module"
            src={`/${js}`}
          />
        ))}

        <DevScriptsHead />
      </head>
    </>
  );
}

function DevScriptsHead() {
  if (PRODUCTION) return null;
  const text = `
import RefreshRuntime from "/@react-refresh"
RefreshRuntime.injectIntoGlobalHook(window)
window.$RefreshReg$ = () => {}
window.$RefreshSig$ = () => (type) => type
window.__vite_plugin_react_preamble_installed__ = true
`;
  return (
    <>
      <script
        type="module"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      <script type="module" src="/@vite/client" />
    </>
  );
}

function DevScriptsBody() {
  if (PRODUCTION) return null;
  return <script type="module" src="/src/entry-client.tsx"></script>;
}

const iconsElement = (
  <>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/icons/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/icons/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/icons/favicon-16x16.png"
    />

    <link rel="manifest" href="/icons/site.webmanifest" />
    <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#787878" />
    <link rel="shortcut icon" href="/icons/favicon.ico" />
    <meta name="msapplication-TileColor" content="#00a300" />
    <meta name="msapplication-config" content="/icons/browserconfig.xml" />
  </>
);
