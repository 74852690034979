import { styled } from '@linaria/react';
import { IconButton } from '@zazcart/ui';
import * as React from 'react';
import { CartRow } from '~/components/CartRows.tsx';
import { Price } from '~/components/price.tsx';
import { CartStateItem, useShoppingCart } from '~/state';

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  color: var(--color-text-default);
  --product-image-radius: 5px;
  --product-image-size: 38px;

  svg {
    width: 7px !important;
    opacity: 0.5;
  }

  &.cart-item-shipment-option {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    .shipment-label {
      width: 100%;
      opacity: 0.7;
      padding-bottom: 2px;
    }
    .shipment-price {
      .Price {
        font-size: var(--font-size-300);
      }
    }
  }
`;

export function CartProducts(props: { items?: CartStateItem[] }) {
  const { items: itemsProp } = props;
  const { cartItems, setItem, shipmentOption, price } = useShoppingCart();
  const items = itemsProp || cartItems;

  return (
    <div className={'CartProducts CartRows'}>
      {items.map((el) => {
        const { id } = el;

        return (
          <Item key={id}>
            <CartRow cartItem={el} />
            <IconButton
              accessibilityLabel={'Remove'}
              icon={'cancel'}
              size={1}
              transparent
              onClick={() => {
                setItem({
                  ...el,
                  quantity: 0,
                });
              }}
            />
          </Item>
        );
      })}

      {items.length && shipmentOption ? (
        <Item className={'cart-item-shipment-option'}>
          <div className="shipment-label">{'Método de envio:'}</div>
          <span className="shipment-name">{shipmentOption.display_name}</span>
          <span className="shipment-price">
            <Price
              value={{
                currencyCode: price.currencyCode,
                amount: shipmentOption.fixed_amount.amount,
              }}
            />
          </span>
        </Item>
      ) : null}
    </div>
  );
}
