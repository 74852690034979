import './LineClamp.scss';

import React, { useMemo } from 'react';
import { usem } from '~/DeviceType.tsx';

export type ClampLinesProps = {
  children: string;
  lines: number;
  ellipsis?: string;
  className?: string;
  // Adicionando aria-label opcional para descrição personalizada
  ariaLabel?: string;
};

export function LineClamp(props: ClampLinesProps) {
  const {
    children,
    lines,
    ellipsis = '...',
    className = '',
    ariaLabel,
  } = props;

  const mobile = usem();

  const { truncatedText, isTruncated } = useMemo(() => {
    const maxChars = (mobile ? 20 : 28) * lines;
    const shouldTruncate = children.length > maxChars;

    if (!shouldTruncate) {
      return { truncatedText: children, isTruncated: false };
    }

    const truncated = children.slice(0, maxChars) + ellipsis;
    return { truncatedText: truncated, isTruncated: true };
  }, [children, lines, ellipsis, mobile]);

  return (
    <div
      className={`line-clamp ${className}`}
      style={{
        WebkitLineClamp: lines,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
      }}
      aria-label={ariaLabel || children}
      aria-hidden={false}
      role="text"
    >
      {truncatedText}
      {isTruncated && <span className="sr-only">{children}</span>}
    </div>
  );
}
