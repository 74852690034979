import { connectReduxDevTools } from '@zazcart/commons';
import { invariant, IS_BROWSER } from 'powership';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useMethodClient } from '~/core/use-method.tsx';
import { AccountLoginState, AccountState } from '~/lib/accounts/ui';

export const AccountContext = React.createContext(
  null as unknown as AccountState,
);

export function useAccount(): AccountState {
  const ctx = React.useContext(AccountContext);
  invariant(ctx, 'useAccount expected AccountProvider to be rendered.');
  return ctx;
}

export type AccountProviderProps = {
  initialLoginState: AccountLoginState;
};

const devtools = IS_BROWSER
  ? connectReduxDevTools({
      field: 'account',
      initialState: {
        loading: false,
        account: window.INSTATE?.accountState,
      },
    })
  : null;

export function AccountProvider(
  props: PropsWithChildren<AccountProviderProps>,
) {
  const { children, initialLoginState } = props;

  const logoutClient = useMethodClient('logout');

  const value = useMemo((): AccountState => {
    return {
      account: initialLoginState,
      loading: false,
      error: '',
      async logout() {
        await logoutClient.exec({});
        window.location.href = '/';
      },
    };
  }, [logoutClient]);

  useEffect(() => {
    devtools?.send({ type: 'change' }, value);
  }, [value]);

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
}
