import './AppLayout.scss';

import { cx, useRendered, useRouter } from '@zazcart/commons';
import { BottomSheet } from '@zazcart/ui';
import { default as React, ReactNode, useEffect } from 'react';
import { PageTitle } from '~/components/AppLayout/PageTitle.tsx';
import { CartFooterActions } from '~/components/product/checkout/CartFooterActions.tsx';
import { CartProducts } from '~/components/product/checkout/CartProducts.tsx';
import { RestoreScroll } from '~/components/RestoreScroll.tsx';
import { useShoppingCart } from '~/state';

export type AppLayoutName = 'initial' | 'centered';

export type AppLayoutProps = {
  className?: string;
  left: ReactNode;
  hero: ReactNode;
  main: ReactNode;
  navbar: ReactNode;
  right: ReactNode;
  layout?: AppLayoutName;
  title?: ReactNode;
  subtitle?: ReactNode;
};

export function AppLayout(props: AppLayoutProps) {
  const { className, main, hero, left, navbar, right, title, layout } = props;

  return (
    <div className={cx('AppLayout', className)}>
      <div id="left-sidebar" className="AppLayout_sidebar left-sidebar">
        {left}
      </div>

      <main
        id="main-content"
        className={cx('AppLayout_mainCenter', {
          centered: title || layout === 'centered',
          'has-navbar': navbar,
          'has-hero': hero,
          'has-right-sidebar': right,
          'has-left-sidebar': left,
        })}
      >
        {navbar !== null && navbar}

        <>
          {hero !== null && <div className="AppLayout_hero">{hero}</div>}

          {(() => {
            if (!main) return null;
            if (title) {
              return (
                <div className="AppLayout_mainCenter">
                  <PageTitle {...props} />
                  <section className="AppLayout_centerContainer">
                    {main}
                  </section>
                </div>
              );
            }
            return <div className="AppLayout_mainCenter">{main}</div>;
          })()}
        </>
      </main>

      <Cart />
      <RestoreScroll />
    </div>
  );
}

function Cart() {
  const {
    layout: { showCart },
    setOpen,
  } = useShoppingCart();

  const rendered = useRendered();

  const { pathname } = useRouter(['pathname']);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <>
      {rendered && (
        <BottomSheet
          open={showCart}
          setOpen={(open) => {
            setOpen(open);
          }}
        >
          <CartProducts />
          <CartFooterActions />
        </BottomSheet>
      )}
    </>
  );
}
