import { cx } from '@zazcart/commons';
import { Link } from '@zazcart/ui';
import React from 'react';
import { useHeaderScroll } from '~/components/app-header/use-header-scroll.tsx';
import { CartButton } from '~/components/cart-button.tsx';
import { Logo } from '~/components/logo.tsx';
import MenuSidebar from '~/components/sidebar/menu-sidebar.tsx';
import { AppSearch } from './app-search.tsx';

export function AppHeaderMobile({ className }: { className?: string }) {
  const { ref } = useHeaderScroll();

  return (
    <header className={cx('app-header header-mobile', className)} ref={ref}>
      <div className="header-wrapper">
        <div className="header-primary">
          <div className="header-navigation">
            <MenuSidebar />
          </div>

          <Link
            href="/"
            className="header-logo"
            accessibilityLabel="Go to home"
          >
            <Logo />
          </Link>

          <div className="header-actions">
            <CartButton />
          </div>
        </div>

        <div className="header-search">
          <AppSearch />
        </div>
      </div>
    </header>
  );
}
