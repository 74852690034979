import { IS_BROWSER } from 'powership';
import { startTransition } from 'react';

export async function setupBrowserChat() {
  if (!IS_BROWSER) return null;

  const Intercom = await import('@intercom/messenger-js-sdk').then(
    (res) => res.default,
  );

  startTransition(() => {
    Intercom({
      alignment: 'left',
      app_id: 'zbt4i3hy',
    });
  });
}
