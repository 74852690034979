import { MethodName, MethodResult, Methods } from '~/core/interfaces.ts';

// @onlyServer
import { MethodContext } from '~/core/method-context.ts';

// @onlyServer
import * as methods from '../methods';

// @onlyBrowser
import client_methods from './client.tsx';

import { ulid } from 'powership';

export type MethodRequest<Method extends MethodName> = {
  method: Method;
  args: Methods[Method]['input'];
  context: MethodContext;
  id?: string;
};

export function runMethod<Method extends MethodName>(
  config: MethodRequest<Method>,
): Promise<MethodResult<Method>> {
  const { args, context, method, id = ulid().slice(4, 7) } = config;
  let result: any;

  // @onlyServer
  result = context.dataLoader(
    method,
    args,
    // @ts-ignore
    methods[method],
  );

  // @onlyBrowser
  result = client_methods[method](args, {}, id);
  return result;
}

if (import.meta.env.DEV) {
  // @onlyServer
  (() => {
    if (typeof window !== 'undefined') {
      window.console.log(`we are not fine`);
    }
  })();
}
