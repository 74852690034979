import './app-header.scss';

import { usem } from '@zazcart/ui';
import React from 'react';
import { AppHeaderDesk } from './app-header-desk.tsx';
import { AppHeaderMobile } from './app-header-mobile.tsx';

export function AppHeader(props: { className?: string }) {
  const mobile = usem();
  const Component = mobile ? AppHeaderMobile : AppHeaderDesk;
  return <Component {...props} />;
}
