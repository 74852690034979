import * as React from 'react';
import { MergeProps } from '~/utils';

import { Button, ButtonProps } from '~/Button.tsx';
import { Icon } from '~/Icon.tsx';
import { IconName, IconSvgProps } from './icons';

export type IconButtonProps = MergeProps<
  ButtonProps,
  IconSvgProps,
  { icon: IconName; size?: 1 | 2 | 3 }
>;

export function IconButton(props: IconButtonProps) {
  const { height, width, icon, size, className, ...otherProps } = props;

  return (
    <Button {...otherProps} size={size} className={['IconButton', className]}>
      <Icon {...{ height, width, size, icon }} accessibilityLabel={''} />
    </Button>
  );
}
