import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { cx, useDimensions, useRendered } from '@zazcart/commons';
import { IS_BROWSER } from 'powership';
import * as React from 'react';
import { lazy } from 'react';
import { usem } from '~/DeviceType.tsx';

// @onlyClient
const SwipeableDrawer = lazy(() => import('@mui/material/SwipeableDrawer'));

const drawerBleeding = 25;

interface BottomSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  container?: HTMLElement | null;
  className?: string;
  width?: number | string;
}

const Root = styled.div`
  height: 100%;
  padding-bottom: var(--gap);
`;

const ContentBox = styled.div`
  margin-top: 50px;
  padding: var(--gap);
  padding-top: 1px;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: var(
    --drawer-background-color,
    var(--color-background-box-elevation-accent)
  );
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  position: absolute;
  top: 3px;
  height: 48px;
  border-top-left-radius: var(--content-rounding);
  border-top-right-radius: var(--content-rounding);
  visibility: visible;
  right: 0;
  left: 0;
  background-color: var(
    --drawer-background-color,
    var(--color-background-box-elevation-accent)
  );
`;

const Puller = styled.div`
  width: 30px;
  height: 6px;
  background-color: var(--color-text-disabled);
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: calc(50% - 15px);
`;

const globalStyles = css`
  :global() {
    .MuiDrawer-root {
      .MuiDrawer-paper {
        background-color: transparent;

        @media screen and (min-width: 1300px) {
          left: unset;
          right: 4px;
          top: 1px;
        }
      }

      > .MuiPaper-root {
        --padding: 2px;
        height: calc(100vh - 100px);
        width: 100%;
        max-width: var(--bottom-sheet-max-width);
        overflow: visible;
        margin-left: auto;
        margin-right: auto;
        @media screen and (min-width: 1300px) {
          height: 100vh;
        }
      }
    }
  }
`;

export function BottomSheet(props: BottomSheetProps) {
  const mobile = usem();

  const {
    className,
    open,
    children,
    setOpen,
    container,
    width = 'min(400px, calc(100% - var(--gap) * 2))',
  } = props;

  if (!IS_BROWSER) return null;
  if (!useRendered()) return null;

  return (
    <Root className={cx(globalStyles, className)}>
      <SwipeableDrawer
        container={
          container ||
          document.getElementById('BottomSheetArea') ||
          document.body
        }
        anchor={mobile ? 'bottom' : 'right'}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        style={{
          // @ts-expect-error
          '--bottom-sheet-max-width':
            typeof width === 'number' ? `${width}px` : width,
        }}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Header className={className}>
          <Puller />
        </Header>

        <ContentBox className={className}>{children}</ContentBox>
      </SwipeableDrawer>
    </Root>
  );
}
