import './FormField.scss';

import { ClassValue, cx } from '@zazcart/commons';
import { AnyFunction, MaybePromise } from 'powership';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { FormDescription, FormLabel } from '~/ariakit.tsx';

import { FieldRenderProps, useField } from 'react-final-form';

import mergeRefs from 'merge-refs';
import { useUniqID } from '~/UIProvider.tsx';
import { MaskDefinition, useMask } from '~/useMask.tsx';
import { MergeProps } from '~/utils';
import { FormError } from './FormError';

export type FieldValidationErrorMessage = string;

export type FieldValidationReturn =
  | FieldValidationErrorMessage
  | null
  | undefined
  | void;

export type FieldValidation = (
  value: string,
  allValues: Record<string, any>,
) => MaybePromise<FieldValidationReturn>;

export type FormFieldEventHandlers = {
  onBlur(ev: React.FocusEvent<HTMLInputElement, Element>): void;
  onFocus(ev: React.FocusEvent<HTMLInputElement, Element>): void;
  onChange(ev: React.ChangeEvent<HTMLInputElement> | string): void;
};

export type FieldWrapperProps = {
  mask?: MaskDefinition;
  prefix?: ReactNode;
  suffix?: ReactNode;
  validate?: FieldValidation;
  name: string;
  label?: ReactNode;
  // If children is undefined, a default InputField is used
  // Below are the values to apply if children is undefined
  required?: boolean;
  hidden?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  description?: ReactNode;
  pre?: ReactNode;
  post?: ReactNode;
  type?: string;
  id?: string;
  defaultValue?: any;
};

export type RenderChildrenInputProps = MergeProps<
  FormFieldEventHandlers,
  { value?: string; checked?: boolean; id: string }
>;

export const FieldWrapper = React.forwardRef<
  any,
  FieldWrapperProps & {
    className?: ClassValue;
    children: (
      inputProps: RenderChildrenInputProps,
      meta: FieldRenderProps<any>['meta'],
    ) => ReactNode;
  }
>(function FieldWrapper(props, ref) {
  const uniqId = useUniqID();

  let {
    name,
    label,
    type,
    hidden = type === 'hidden',
    children,
    className,
    pre,
    post,
    description,
    prefix,
    suffix,
    validate,
    mask: maskProp,
    defaultValue,
    id = `field-${name}-${uniqId}`,
    ...otherProps
  } = props;

  const { required } = props;

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  function safe(run: AnyFunction) {
    if (!mounted.current) return;
    return run();
  }

  const nameClass = useMemo(
    () => 'field_' + name.replace(/[^a-z]/i, '_'),
    [name],
  );

  const renderProps = useField(name, {
    type,
    defaultValue,
    validate: async (value = '', allValues) => {
      return safe(async () => {
        let error = await validate?.(value, allValues as Record<string, any>);
        if (!error && required && empty(value)) {
          const naming = label || 'Este campo';
          error = `${naming} é obrigatório`;
        }
        return error;
      });
    },
  });

  let { error, touched, active } = renderProps.meta;

  const errorNode = (() => {
    if (active || !touched || !error) return null;
    return typeof error === 'string' ? error : 'Valor inválido';
  })();

  const { initial: initialValue } = renderProps.meta;

  const mask = maskProp ? useMask(maskProp, { initialValue }) : null;

  return (
    <div
      className={cx(
        'FormField',
        nameClass,
        { hidden, [`type-${type}`]: type, 'has-error': error },
        className,
      )}
    >
      <>
        {pre}

        {!hidden && !!label && <FormLabel name={name}>{label}</FormLabel>}

        <div
          className={cx('FormField_container', {
            hasPrefix: prefix,
            hasSuffix: suffix,
          })}
        >
          {prefix ? <div className={'FormField_prefix'}>{prefix}</div> : null}

          <div className="FormField_childWrapper">
            {children(
              {
                id,
                ...renderProps.input,
                value: mask ? mask.value : renderProps.input.value,
                'aria-invalid': errorNode ? true : undefined,
                'aria-errormessage': errorNode ? errorNode : undefined,
                ...otherProps,
                ref: mergeRefs(mask?.ref, ref, renderProps.input.ref),
                onBlur(ev: React.FocusEvent<HTMLInputElement, Element>) {
                  return safe(() => {
                    // @ts-ignore
                    props.onBlur?.(ev);
                    renderProps.input.onBlur(ev);
                  });
                },
                onFocus(ev: React.FocusEvent<HTMLInputElement, Element>) {
                  return safe(() => {
                    // @ts-ignore
                    props.onFocus?.(ev);
                    renderProps.input.onFocus(ev);
                  });
                },
                onChange(ev: React.ChangeEvent<HTMLInputElement> | string) {
                  return safe(() => {
                    if (typeof ev !== 'string') {
                      // @ts-ignore
                      props.onChange?.(ev);
                    }
                    renderProps.input.onChange(ev);
                  });
                },
              } as unknown as RenderChildrenInputProps,
              renderProps.meta,
            )}
          </div>

          {suffix ? <div className={'FormField_suffix'}>{suffix}</div> : null}
        </div>

        {!!description && (
          <FormDescription name={name}>{description}</FormDescription>
        )}

        {errorNode && <FormError name={name}>{error}</FormError>}
        {post}
      </>
    </div>
  );
});

function empty(value: unknown) {
  return value === undefined || value === null || value === '';
}
