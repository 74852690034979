import { PRODUCTION } from '@zazcart/commons';
import posthog from 'posthog-js';
import { IS_BROWSER } from 'powership';
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';
import { EventDataMap, EventName } from '~/core/telemetry/event-types.ts';

export type AnalyticsContextValue = {
  capture<Event extends EventName>(
    event: Event,
    data: EventDataMap[Event],
  ): void;
};

export const TelemetryContext = createContext(
  {} as unknown as AnalyticsContextValue,
);

export function useTelemetry() {
  return React.useContext(TelemetryContext);
}

export function TelemetryProvider(props: PropsWithChildren) {
  useEffect(() => {
    if (!PRODUCTION) return;
    posthog.init('phc_Ii2tda34CaTmAUGpYvv8kSN7Dh53rA4aY9VlpzJ7g1x', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
    });
  }, []);

  const value = useMemo((): AnalyticsContextValue => {
    return {
      capture<Event extends EventName>(
        event: Event,
        data: EventDataMap[Event],
      ) {
        if (!IS_BROWSER) return;
        if (!PRODUCTION) return;

        posthog.capture(event, data);

        if (event.startsWith('ecommerce_')) {
          window.dataLayer!.push({
            event: event.replace('ecommerce_', ''),
            ecommerce: data,
          });
        } else {
          window.dataLayer!.push({
            event: event,
            data,
          });
        }
      },
    };
  }, []);

  return (
    <TelemetryContext.Provider value={value}>
      {props.children}
    </TelemetryContext.Provider>
  );
}

declare global {
  interface Window {
    dataLayer?: object[];
  }
}
