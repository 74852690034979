import { connectReduxDevTools, superjson } from '@zazcart/commons';
import { IS_BROWSER } from 'powership';

interface MethodError extends Error {
  code?: string;
  status?: number;
  details?: any;
}

class MethodCallError extends Error implements MethodError {
  code: string;
  status: number;
  details: any;

  constructor(message: string, status: number, code?: string, details?: any) {
    super(message);
    this.name = 'MethodCallError';
    this.code = code || 'UNKNOWN_ERROR';
    this.status = status;
    this.details = details;
  }
}

let methods = Object.create(null);

const proxy = new Proxy(Object.create(null), {
  get(_, key: string) {
    return (methods[key] = methods[key] || createMethod(key));
  },
});

const devtools = IS_BROWSER
  ? connectReduxDevTools({ field: 'methods', initialState: {} })
  : null;

function parseResponseData(result: Response, txt: string) {
  const content = result.headers.get('content-type');

  try {
    if (content?.match('application/json') && txt.trim().match(/^\[|\{/)) {
      return superjson.parse(txt);
    }
    return txt;
  } catch (error) {
    throw new MethodCallError(
      'Failed to parse response data',
      result.status,
      'PARSE_ERROR',
      { raw: txt },
    );
  }
}

async function handleErrorResponse(result: Response): Promise<never> {
  const txt = await result.text();
  let errorData;

  try {
    errorData = JSON.parse(txt);
  } catch {
    errorData = { message: txt };
  }

  throw new MethodCallError(
    errorData.message || 'Request failed',
    result.status,
    errorData.code,
    errorData.details,
  );
}

function createMethod(method: string) {
  async function clientMethod(args: {}, headers = {}, id: string) {
    devtools?.send({ type: `${method}/${id}/call`, args }, null);

    try {
      const result = await fetch(`/methods?${method}&id=${id}`, {
        method: 'POST',
        headers: {
          ...headers,
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          method,
          args,
        }),
      });

      if (!result.ok) {
        await handleErrorResponse(result);
      }

      const txt = await result.text();
      const parsedData = parseResponseData(result, txt);

      devtools?.send(
        { type: `${method}/${id}/result`, args, result: parsedData },
        null,
      );
      return parsedData;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error occurred';

      devtools?.send(
        { type: `${method}/${id}/error`, args, err: JSON.stringify(error) },
        null,
      );

      if (error instanceof MethodCallError) {
        throw error;
      }

      throw new MethodCallError(errorMessage, 500, 'INTERNAL_ERROR', {
        originalError: error,
      });
    }
  }

  Object.defineProperties(clientMethod, {
    name: { value: `${method}_client` },
  });

  return clientMethod;
}

export default proxy;
