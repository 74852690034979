import { DateTime, type DateTimeOptions } from 'luxon';
import { useMemo } from 'react';

export type * from 'luxon';

export { DateTime };

export type DateOrMillis = Date | number | string;

export function dates(dateOrMillis: DateOrMillis) {
  if (typeof dateOrMillis === 'string') {
    return DateTime.fromJSDate(new Date(dateOrMillis));
  }
  if (typeof dateOrMillis === 'number') {
    return DateTime.fromMillis(dateOrMillis);
  }
  return DateTime.fromJSDate(dateOrMillis);
}

dates.fromString = function dateFromString(
  formatted: string,
  format: string,
  options?: DateTimeOptions,
) {
  return DateTime.fromFormat(formatted, format, options);
};

export type DateFormatProps = {
  date: DateOrMillis | null | undefined;
  format?: string;
  defaultText?: string;
};

export let DEFAULT_DATE_FORMAT = 'dd/mm/yyyy HH:mm';

export function useDateFormat(props: DateFormatProps) {
  const { date, format = DEFAULT_DATE_FORMAT, defaultText = '-' } = props;
  return useMemo(() => {
    if (date === null || date === undefined) return defaultText;
    return dates(date).toFormat(format);
  }, [date, format]);
}

export function DateFormat(props: DateFormatProps): string {
  return useDateFormat(props);
}

export const ONE_DAY_MS = 3.456e8;
