import { useEffect, useRef } from 'react';

export function useHeaderScroll() {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    let lastScrollPosition = document.body.scrollTop;

    let direction = 'down';

    let locked = false;
    function lock() {
      if (locked) return;
      locked = true;
      setTimeout(() => {
        locked = false;
      }, 80);
    }

    function handleScroll() {
      let scrollY = document.body.scrollTop;

      const lastY = lastScrollPosition;
      lastScrollPosition = scrollY;

      const scrollingDown = scrollY > lastY;
      const nextDirection = scrollingDown ? 'down' : 'up';
      if (nextDirection !== direction) lock();
      direction = nextDirection;
      if (locked) return;

      const scrolled = scrollY > 2;

      document.body.classList[scrolled ? 'add' : 'remove']('scrolled');

      if (ref.current) {
        const up = scrolled && nextDirection === 'up';
        const down = scrolled && nextDirection === 'down';

        if (scrolled) {
          if (up) {
            ref.current.classList.add('scrolling-up', 'scrolled');
            ref.current.classList.remove('scrolling-down');
            document.body.classList.remove('scrolled');
          }

          if (down) {
            ref.current.classList.add('scrolling-down', 'scrolled');
            ref.current.classList.remove('scrolling-up');
            const translateY = Math.min(scrollY, ref.current.offsetHeight);
            ref.current.style.setProperty('--translateY', `-${translateY}px`);
          }
        } else {
          ref.current.classList.remove(
            'scrolling-up',
            'scrolling-down',
            'scrolled',
          );
          ref.current.style.transform = '';
        }
      }
    }

    document.body.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { ref };
}

//function debounceScroll(
//   callback: () => void,
//   timeoutMs = 80,
//   intervalMs = 300,
//   canRun = () => true,
// ) {
//   let timeout: any;
//   let interval: any;
//   let isRunning = false;
//
//   function schedule() {
//     if (!isRunning) {
//       isRunning = true;
//       interval = setInterval(() => {
//         clearTimeout(timeout);
//         callback();
//       }, intervalMs);
//     }
//
//     if (!canRun()) return;
//
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       isRunning = false;
//       clearInterval(interval);
//       callback();
//     }, timeoutMs);
//   }
//
//   return {
//     schedule,
//     clear() {
//       clearTimeout(timeout);
//       clearInterval(interval);
//       isRunning = false;
//     },
//   };
// }
