import {
  createRouter as createRouterCommons,
  defineRoutes,
} from '@zazcart/commons';

import type { MethodContext } from '~/core/method-context.ts';

export const appRoutes = defineRoutes({
  home: {
    path: '/',
    component: () => import('./home.tsx'),
  },
  cart: {
    path: '/cart',
    component: () => import('./cart.tsx'),
  },
  checkout: {
    path: '/checkout',
    component: () => import('./checkout.tsx'),
  },
  paymentResult: {
    path: '/payment-result',
    component: () => import('./payment-result/payment-result.tsx'),
  },
  collections: {
    path: '/collections/:collection',
    component: () => import('./collections.tsx'),
  },
  orders: {
    path: '/orders',
    component: () => import('./orders.tsx'),
  },
  products: {
    path: '/products/:slug',
    component: () => import('./products.tsx'),
  },
  search: {
    path: '/search',
    component: () => import('./search.tsx'),
  },
  login: {
    path: '/login',
    component: () => import('./login.tsx'),
  },
  terms: {
    path: '/terms',
    component: () => import('./terms.tsx'),
  },
} as const);

export function createRouter() {
  return createRouterCommons({
    routes: appRoutes,
    async preloadData({ context }) {
      if (!context) return; // client side
      await Promise.allSettled([
        context.call('loadCartData', {}),
        context.call('getCollections', {}),
      ]);
    },
  });
}

export type AppRoutes = typeof appRoutes;

declare module '@zazcart/commons' {
  export interface ServerFetchContext extends MethodContext {}
}

declare module '@zazcart/commons' {
  export interface AppRoutesConfig extends AppRoutes {}
}
