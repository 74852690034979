import { styled } from '@linaria/react';
import { cx } from '@zazcart/commons';
import { Link } from '@zazcart/ui';
import React, { useEffect, useState } from 'react';
import { IconShoppingCart } from '~/components/icon-shopping-cart.tsx';
import { useShoppingCart } from '~/state';

const Button = styled(Link)`
  &&& {
    width: 40px;
    height: 43px;
  }
`;

const CountLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--color-icon-subtle);
  padding: 3px 6px;
  border-radius: 19px;
  font-size: 10px;
  color: white;
  top: 1px;
  right: 0;
  font-family: system-ui;

  transition: background-color 300ms;

  &.count {
    background-color: var(--color-purple-mysticool-400);
  }
  &.highlight {
    background-color: var(--color-purple-mysticool-600);
  }
`;

export function CartButton() {
  const { count, toggleOpen } = useShoppingCart();
  const [prevCount, setPrevCount] = useState(count);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (count > prevCount) {
      setIsAnimating(true);
      const timer = setTimeout(() => setIsAnimating(false), 1000);
      return () => clearTimeout(timer);
    }
    setPrevCount(count);
  }, [count, prevCount]);

  return (
    <Button
      href={'/cart'}
      accessibilityLabel={'Open Cart'}
      rounding={'circle'}
      className={'IconButton'}
      onClick={(ev) => {
        ev.preventDefault();
        toggleOpen();
      }}
    >
      {!!count && (
        <CountLabel
          key={count}
          className={cx(isAnimating ? 'highlight' : '', { count })}
        >
          {count}
        </CountLabel>
      )}
      <IconShoppingCart />
    </Button>
  );
}
