import './ariakit.scss';

import {
  Checkbox as AriaCheckbox,
  CheckboxCheck as AriaCheckboxCheck,
  CheckboxProvider as AriaCheckboxProvider,
  Collection as AriaCollection,
  CollectionItem as AriaCollectionItem,
  CollectionProvider as AriaCollectionProvider,
  Combobox as AriaCombobox,
  ComboboxCancel as AriaComboboxCancel,
  ComboboxDisclosure as AriaComboboxDisclosure,
  ComboboxGroup as AriaComboboxGroup,
  ComboboxGroupLabel as AriaComboboxGroupLabel,
  ComboboxItem as AriaComboboxItem,
  ComboboxItemCheck as AriaComboboxItemCheck,
  ComboboxItemValue as AriaComboboxItemValue,
  ComboboxLabel as AriaComboboxLabel,
  ComboboxList as AriaComboboxList,
  ComboboxPopover as AriaComboboxPopover,
  ComboboxProvider as AriaComboboxProvider,
  ComboboxRow as AriaComboboxRow,
  ComboboxSeparator as AriaComboboxSeparator,
  ComboboxValue as AriaComboboxValue,
  Command as AriaCommand,
  Composite as AriaComposite,
  CompositeGroup as AriaCompositeGroup,
  CompositeGroupLabel as AriaCompositeGroupLabel,
  CompositeHover as AriaCompositeHover,
  CompositeItem as AriaCompositeItem,
  CompositeProvider as AriaCompositeProvider,
  CompositeRow as AriaCompositeRow,
  CompositeSeparator as AriaCompositeSeparator,
  CompositeTypeahead as AriaCompositeTypeahead,
  Dialog as AriaDialog,
  DialogDescription as AriaDialogDescription,
  DialogDisclosure as AriaDialogDisclosure,
  DialogDismiss as AriaDialogDismiss,
  DialogHeading as AriaDialogHeading,
  DialogProvider as AriaDialogProvider,
  Disclosure as AriaDisclosure,
  DisclosureContent as AriaDisclosureContent,
  DisclosureProvider as AriaDisclosureProvider,
  Focusable as AriaFocusable,
  FocusTrap as AriaFocusTrap,
  FocusTrapRegion as AriaFocusTrapRegion,
  FormCheckbox as AriaFormCheckbox,
  FormControl as AriaFormControl,
  FormDescription as AriaFormDescription,
  FormGroupLabel as AriaFormGroupLabel,
  FormInput as AriaFormInput,
  FormLabel as AriaFormLabel,
  FormProvider as AriaFormProvider,
  FormPush as AriaFormPush,
  FormRadio as AriaFormRadio,
  FormRadioGroup as AriaFormRadioGroup,
  FormRemove as AriaFormRemove,
  Group as AriaGroup,
  GroupLabel as AriaGroupLabel,
  HeadingLevel as AriaHeadingLevel,
  Hovercard as AriaHovercard,
  HovercardAnchor as AriaHovercardAnchor,
  HovercardArrow as AriaHovercardArrow,
  HovercardDescription as AriaHovercardDescription,
  HovercardDisclosure as AriaHovercardDisclosure,
  HovercardDismiss as AriaHovercardDismiss,
  HovercardHeading as AriaHovercardHeading,
  HovercardProvider as AriaHovercardProvider,
  MenuArrow as AriaMenuArrow,
  MenuBar as AriaMenuBar,
  Menubar as AriaMenubar,
  MenuBarProvider as AriaMenuBarProvider,
  MenubarProvider as AriaMenubarProvider,
  MenuButton as AriaMenuButton,
  MenuButtonArrow as AriaMenuButtonArrow,
  MenuDescription as AriaMenuDescription,
  MenuDismiss as AriaMenuDismiss,
  MenuGroup as AriaMenuGroup,
  MenuGroupLabel as AriaMenuGroupLabel,
  MenuHeading as AriaMenuHeading,
  MenuItemCheck as AriaMenuItemCheck,
  MenuItemCheckbox as AriaMenuItemCheckbox,
  MenuItemRadio as AriaMenuItemRadio,
  MenuList as AriaMenuList,
  MenuProvider as AriaMenuProvider,
  MenuSeparator as AriaMenuSeparator,
  Popover as AriaPopover,
  PopoverAnchor as AriaPopoverAnchor,
  PopoverArrow as AriaPopoverArrow,
  PopoverDescription as AriaPopoverDescription,
  PopoverDisclosure as AriaPopoverDisclosure,
  PopoverDisclosureArrow as AriaPopoverDisclosureArrow,
  PopoverDismiss as AriaPopoverDismiss,
  PopoverHeading as AriaPopoverHeading,
  PopoverProvider as AriaPopoverProvider,
  Portal as AriaPortal,
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  RadioProvider as AriaRadioProvider,
  Role as AriaRole,
  Select as AriaSelect,
  SelectArrow as AriaSelectArrow,
  SelectDismiss as AriaSelectDismiss,
  SelectGroup as AriaSelectGroup,
  SelectGroupLabel as AriaSelectGroupLabel,
  SelectHeading as AriaSelectHeading,
  SelectItem as AriaSelectItem,
  SelectItemCheck as AriaSelectItemCheck,
  SelectLabel as AriaSelectLabel,
  SelectList as AriaSelectList,
  SelectPopover as AriaSelectPopover,
  SelectProvider as AriaSelectProvider,
  SelectRow as AriaSelectRow,
  SelectSeparator as AriaSelectSeparator,
  SelectValue as AriaSelectValue,
  Separator as AriaSeparator,
  Tab as AriaTab,
  TabList as AriaTabList,
  TabPanel as AriaTabPanel,
  TabProvider as AriaTabProvider,
  Toolbar as AriaToolbar,
  ToolbarContainer as AriaToolbarContainer,
  ToolbarInput as AriaToolbarInput,
  ToolbarItem as AriaToolbarItem,
  ToolbarProvider as AriaToolbarProvider,
  ToolbarSeparator as AriaToolbarSeparator,
  Tooltip as AriaTooltip,
  TooltipAnchor as AriaTooltipAnchor,
  TooltipArrow as AriaTooltipArrow,
  TooltipProvider as AriaTooltipProvider,
  VisuallyHidden as AriaVisuallyHidden,
} from '@ariakit/react';
import {
  withVariant,
  withVariantForBadlyTypedComponents,
} from '~/variants.tsx';

export {
  PortalContext,
  useCheckboxContext,
  useCheckboxStore,
  useCollectionContext,
  useCollectionStore,
  useComboboxContext,
  useComboboxStore,
  useCompositeContext,
  useCompositeStore,
  useDialogContext,
  useDialogStore,
  useDisclosureContext,
  useDisclosureStore,
  useHovercardContext,
  useHovercardStore,
  useMenuBarContext,
  useMenubarContext,
  useMenuBarStore,
  useMenubarStore,
  useMenuContext,
  useMenuStore,
  usePopoverContext,
  usePopoverStore,
  useRadioContext,
  useRadioStore,
  useSelectContext,
  useSelectStore,
  useStoreState,
  useTabContext,
  useTabStore,
  useToolbarContext,
  useToolbarStore,
  useTooltipContext,
  useTooltipStore,
} from '@ariakit/react';

export const Checkbox = withVariant('Checkbox', AriaCheckbox);

export const CheckboxCheck = withVariant('CheckboxCheck', AriaCheckboxCheck);

export const CheckboxProvider = withVariant(
  'CheckboxProvider',
  AriaCheckboxProvider,
);

export const Collection = withVariant('Collection', AriaCollection);

export const CollectionItem = withVariant('CollectionItem', AriaCollectionItem);

export const CollectionProvider = withVariant(
  'CollectionProvider',
  AriaCollectionProvider,
);

export const Combobox = withVariant('Combobox', AriaCombobox);

export const ComboboxCancel = withVariant('ComboboxCancel', AriaComboboxCancel);

export const ComboboxDisclosure = withVariant(
  'ComboboxDisclosure',
  AriaComboboxDisclosure,
);

export const ComboboxGroup = withVariant('ComboboxGroup', AriaComboboxGroup);

export const ComboboxGroupLabel = withVariant(
  'ComboboxGroupLabel',
  AriaComboboxGroupLabel,
);

export const ComboboxItem = withVariant('ComboboxItem', AriaComboboxItem);

export const ComboboxItemCheck = withVariant(
  'ComboboxItemCheck',
  AriaComboboxItemCheck,
);

export const ComboboxItemValue = withVariant(
  'ComboboxItemValue',
  AriaComboboxItemValue,
);

export const ComboboxLabel = withVariant('ComboboxLabel', AriaComboboxLabel);

export const ComboboxList = withVariant('ComboboxList', AriaComboboxList);

export const ComboboxPopover = withVariantForBadlyTypedComponents(
  'ComboboxPopover',
  AriaComboboxPopover,
);

export const ComboboxProvider = withVariant(
  'ComboboxProvider',
  AriaComboboxProvider,
);

export const ComboboxRow = withVariant('ComboboxRow', AriaComboboxRow);

export const ComboboxSeparator = withVariant(
  'ComboboxSeparator',
  AriaComboboxSeparator,
);

export const ComboboxValue = withVariant('ComboboxValue', AriaComboboxValue);

export const Command = withVariant('Command', AriaCommand);

export const Composite = withVariant('Composite', AriaComposite);

export const CompositeGroup = withVariant('CompositeGroup', AriaCompositeGroup);

export const CompositeGroupLabel = withVariant(
  'CompositeGroupLabel',
  AriaCompositeGroupLabel,
);

export const CompositeHover = withVariant('CompositeHover', AriaCompositeHover);

export const CompositeItem = withVariant('CompositeItem', AriaCompositeItem);

export const CompositeProvider = withVariant(
  'CompositeProvider',
  AriaCompositeProvider,
);

export const CompositeRow = withVariant('CompositeRow', AriaCompositeRow);

export const CompositeSeparator = withVariant(
  'CompositeSeparator',
  AriaCompositeSeparator,
);

export const CompositeTypeahead = withVariant(
  'CompositeTypeahead',
  AriaCompositeTypeahead,
);

export const Dialog = withVariant('Dialog', AriaDialog);

export const DialogDescription = withVariant(
  'DialogDescription',
  AriaDialogDescription,
);

export const DialogDisclosure = withVariant(
  'DialogDisclosure',
  AriaDialogDisclosure,
);

export const DialogDismiss = withVariant('DialogDismiss', AriaDialogDismiss);

export const DialogHeading = withVariant('DialogHeading', AriaDialogHeading);

export const DialogProvider = withVariant('DialogProvider', AriaDialogProvider);

export const Disclosure = withVariant('Disclosure', AriaDisclosure);

export const DisclosureContent = withVariant(
  'DisclosureContent',
  AriaDisclosureContent,
);

export const DisclosureProvider = withVariant(
  'DisclosureProvider',
  AriaDisclosureProvider,
);

export const FocusTrap = withVariant('FocusTrap', AriaFocusTrap);

export const FocusTrapRegion = withVariant(
  'FocusTrapRegion',
  AriaFocusTrapRegion,
);

export const Focusable = withVariant('Focusable', AriaFocusable);

export const FormCheckbox = withVariant('FormCheckbox', AriaFormCheckbox);

export const FormControl = withVariant('FormControl', AriaFormControl);

export const FormDescription = withVariant(
  'FormDescription',
  AriaFormDescription,
);

export const FormGroupLabel = withVariant('FormGroupLabel', AriaFormGroupLabel);

export const FormInput = withVariant('FormInput', AriaFormInput);

export const FormLabel = withVariant('FormLabel', AriaFormLabel);

export const FormProvider = withVariant('FormProvider', AriaFormProvider);

export const FormPush = withVariant('FormPush', AriaFormPush);

export const FormRadio = withVariant('FormRadio', AriaFormRadio);

export const FormRadioGroup = withVariant('FormRadioGroup', AriaFormRadioGroup);

export const FormRemove = withVariant('FormRemove', AriaFormRemove);

export const Group = withVariant('Group', AriaGroup);

export const GroupLabel = withVariant('GroupLabel', AriaGroupLabel);

export const HeadingLevel = withVariant('HeadingLevel', AriaHeadingLevel);

export const Hovercard = withVariantForBadlyTypedComponents(
  'Hovercard',
  AriaHovercard,
);

export const HovercardAnchor = withVariant(
  'HovercardAnchor',
  AriaHovercardAnchor,
);

export const HovercardArrow = withVariant('HovercardArrow', AriaHovercardArrow);

export const HovercardDescription = withVariant(
  'HovercardDescription',
  AriaHovercardDescription,
);

export const HovercardDisclosure = withVariant(
  'HovercardDisclosure',
  AriaHovercardDisclosure,
);

export const HovercardDismiss = withVariant(
  'HovercardDismiss',
  AriaHovercardDismiss,
);

export const HovercardHeading = withVariant(
  'HovercardHeading',
  AriaHovercardHeading,
);

export const HovercardProvider = withVariant(
  'HovercardProvider',
  AriaHovercardProvider,
);

export const MenuArrow = withVariant('MenuArrow', AriaMenuArrow);

export const MenuBar = withVariant('MenuBar', AriaMenuBar);

export const MenuBarProvider = withVariant(
  'MenuBarProvider',
  AriaMenuBarProvider,
);

export const MenuButton = withVariant('MenuButton', AriaMenuButton);

export const MenuButtonArrow = withVariant(
  'MenuButtonArrow',
  AriaMenuButtonArrow,
);

export const MenuDescription = withVariant(
  'MenuDescription',
  AriaMenuDescription,
);

export const MenuDismiss = withVariant('MenuDismiss', AriaMenuDismiss);

export const MenuGroup = withVariant('MenuGroup', AriaMenuGroup);

export const MenuGroupLabel = withVariant('MenuGroupLabel', AriaMenuGroupLabel);

export const MenuHeading = withVariant('MenuHeading', AriaMenuHeading);

export const MenuItemCheck = withVariant('MenuItemCheck', AriaMenuItemCheck);

export const MenuItemCheckbox = withVariant(
  'MenuItemCheckbox',
  AriaMenuItemCheckbox,
);

export const MenuItemRadio = withVariant('MenuItemRadio', AriaMenuItemRadio);

export const MenuList = withVariant('MenuList', AriaMenuList);

export const MenuProvider = withVariant('MenuProvider', AriaMenuProvider);

export const MenuSeparator = withVariant('MenuSeparator', AriaMenuSeparator);

export const Menubar = withVariant('Menubar', AriaMenubar);

export const MenubarProvider = withVariant(
  'MenubarProvider',
  AriaMenubarProvider,
);

export const Popover = withVariantForBadlyTypedComponents(
  'Popover',
  AriaPopover,
);

export const PopoverAnchor = withVariant('PopoverAnchor', AriaPopoverAnchor);

export const PopoverArrow = withVariant('PopoverArrow', AriaPopoverArrow);

export const PopoverDescription = withVariant(
  'PopoverDescription',
  AriaPopoverDescription,
);

export const PopoverDisclosure = withVariant(
  'PopoverDisclosure',
  AriaPopoverDisclosure,
);

export const PopoverDisclosureArrow = withVariant(
  'PopoverDisclosureArrow',
  AriaPopoverDisclosureArrow,
);

export const PopoverDismiss = withVariant('PopoverDismiss', AriaPopoverDismiss);

export const PopoverHeading = withVariant('PopoverHeading', AriaPopoverHeading);

export const PopoverProvider = withVariant(
  'PopoverProvider',
  AriaPopoverProvider,
);

export const Portal = withVariant('Portal', AriaPortal);

export const Radio = withVariant('Radio', AriaRadio);

export const RadioGroup = withVariant('RadioGroup', AriaRadioGroup);

export const RadioProvider = withVariant('RadioProvider', AriaRadioProvider);

export const Role = withVariant('Role', AriaRole);

export const Select = withVariant('Select', AriaSelect);

export const SelectArrow = withVariant('SelectArrow', AriaSelectArrow);

export const SelectDismiss = withVariant('SelectDismiss', AriaSelectDismiss);

export const SelectGroup = withVariant('SelectGroup', AriaSelectGroup);

export const SelectGroupLabel = withVariant(
  'SelectGroupLabel',
  AriaSelectGroupLabel,
);

export const SelectHeading = withVariant('SelectHeading', AriaSelectHeading);

export const SelectItem = withVariant('SelectItem', AriaSelectItem);

export const SelectItemCheck = withVariant(
  'SelectItemCheck',
  AriaSelectItemCheck,
);

export const SelectLabel = withVariant('SelectLabel', AriaSelectLabel);

export const SelectList = withVariant('SelectList', AriaSelectList);

export const SelectPopover = withVariantForBadlyTypedComponents(
  'SelectPopover',
  AriaSelectPopover,
);

export const SelectProvider = withVariant('SelectProvider', AriaSelectProvider);

export const SelectRow = withVariant('SelectRow', AriaSelectRow);

export const SelectSeparator = withVariant(
  'SelectSeparator',
  AriaSelectSeparator,
);

export const SelectValue = withVariant('SelectValue', AriaSelectValue);

export const Separator = withVariant('Separator', AriaSeparator);

export const Tab = withVariant('Tab', AriaTab);

export const TabList = withVariant('TabList', AriaTabList);

export const TabPanel = withVariant('TabPanel', AriaTabPanel);

export const TabProvider = withVariant('TabProvider', AriaTabProvider);

export const Toolbar = withVariant('Toolbar', AriaToolbar);

export const ToolbarContainer = withVariant(
  'ToolbarContainer',
  AriaToolbarContainer,
);

export const ToolbarInput = withVariant('ToolbarInput', AriaToolbarInput);

export const ToolbarItem = withVariant('ToolbarItem', AriaToolbarItem);

export const ToolbarProvider = withVariant(
  'ToolbarProvider',
  AriaToolbarProvider,
);

export const ToolbarSeparator = withVariant(
  'ToolbarSeparator',
  AriaToolbarSeparator,
);

export const Tooltip = withVariantForBadlyTypedComponents(
  'Tooltip',
  AriaTooltip,
);

export const TooltipAnchor = withVariant('TooltipAnchor', AriaTooltipAnchor);

export const TooltipArrow = withVariant('TooltipArrow', AriaTooltipArrow);

export const TooltipProvider = withVariant(
  'TooltipProvider',
  AriaTooltipProvider,
);

export const VisuallyHidden = withVariant('VisuallyHidden', AriaVisuallyHidden);

export type CheckboxProps = Parameters<typeof Checkbox>[0];

export type CheckboxCheckProps = Parameters<typeof CheckboxCheck>[0];

export type CheckboxProviderProps = Parameters<typeof CheckboxProvider>[0];

export type CollectionProps = Parameters<typeof Collection>[0];

export type CollectionItemProps = Parameters<typeof CollectionItem>[0];

export type CollectionProviderProps = Parameters<typeof CollectionProvider>[0];

export type ComboboxProps = Parameters<typeof Combobox>[0];

export type ComboboxCancelProps = Parameters<typeof ComboboxCancel>[0];

export type ComboboxDisclosureProps = Parameters<typeof ComboboxDisclosure>[0];

export type ComboboxGroupProps = Parameters<typeof ComboboxGroup>[0];

export type ComboboxGroupLabelProps = Parameters<typeof ComboboxGroupLabel>[0];

export type ComboboxItemProps = Parameters<typeof ComboboxItem>[0];

export type ComboboxItemCheckProps = Parameters<typeof ComboboxItemCheck>[0];

export type ComboboxItemValueProps = Parameters<typeof ComboboxItemValue>[0];

export type ComboboxLabelProps = Parameters<typeof ComboboxLabel>[0];

export type ComboboxListProps = Parameters<typeof ComboboxList>[0];

export type ComboboxPopoverProps = Parameters<typeof ComboboxPopover>[0];

export type ComboboxProviderProps = Parameters<typeof ComboboxProvider>[0];

export type ComboboxRowProps = Parameters<typeof ComboboxRow>[0];

export type ComboboxSeparatorProps = Parameters<typeof ComboboxSeparator>[0];

export type ComboboxValueProps = Parameters<typeof ComboboxValue>[0];

export type CommandProps = Parameters<typeof Command>[0];

export type CompositeProps = Parameters<typeof Composite>[0];

export type CompositeGroupProps = Parameters<typeof CompositeGroup>[0];

export type CompositeGroupLabelProps = Parameters<
  typeof CompositeGroupLabel
>[0];

export type CompositeHoverProps = Parameters<typeof CompositeHover>[0];

export type CompositeItemProps = Parameters<typeof CompositeItem>[0];

export type CompositeProviderProps = Parameters<typeof CompositeProvider>[0];

export type CompositeRowProps = Parameters<typeof CompositeRow>[0];

export type CompositeSeparatorProps = Parameters<typeof CompositeSeparator>[0];

export type CompositeTypeaheadProps = Parameters<typeof CompositeTypeahead>[0];

export type DialogProps = Parameters<typeof Dialog>[0];

export type DialogDescriptionProps = Parameters<typeof DialogDescription>[0];

export type DialogDisclosureProps = Parameters<typeof DialogDisclosure>[0];

export type DialogDismissProps = Parameters<typeof DialogDismiss>[0];

export type DialogHeadingProps = Parameters<typeof DialogHeading>[0];

export type DialogProviderProps = Parameters<typeof DialogProvider>[0];

export type DisclosureProps = Parameters<typeof Disclosure>[0];

export type DisclosureContentProps = Parameters<typeof DisclosureContent>[0];

export type DisclosureProviderProps = Parameters<typeof DisclosureProvider>[0];

export type FocusTrapProps = Parameters<typeof FocusTrap>[0];

export type FocusTrapRegionProps = Parameters<typeof FocusTrapRegion>[0];

export type FocusableProps = Parameters<typeof Focusable>[0];

export type FormCheckboxProps = Parameters<typeof FormCheckbox>[0];

export type FormControlProps = Parameters<typeof FormControl>[0];

export type FormDescriptionProps = Parameters<typeof FormDescription>[0];

export type FormGroupLabelProps = Parameters<typeof FormGroupLabel>[0];

export type FormInputProps = Parameters<typeof FormInput>[0];

export type FormLabelProps = Parameters<typeof FormLabel>[0];

export type FormProviderProps = Parameters<typeof FormProvider>[0];

export type FormPushProps = Parameters<typeof FormPush>[0];

export type FormRadioProps = Parameters<typeof FormRadio>[0];

export type FormRadioGroupProps = Parameters<typeof FormRadioGroup>[0];

export type FormRemoveProps = Parameters<typeof FormRemove>[0];

export type GroupProps = Parameters<typeof Group>[0];

export type GroupLabelProps = Parameters<typeof GroupLabel>[0];

export type HeadingLevelProps = Parameters<typeof HeadingLevel>[0];

export type HovercardProps = Parameters<typeof Hovercard>[0];

export type HovercardAnchorProps = Parameters<typeof HovercardAnchor>[0];

export type HovercardArrowProps = Parameters<typeof HovercardArrow>[0];

export type HovercardDescriptionProps = Parameters<
  typeof HovercardDescription
>[0];

export type HovercardDisclosureProps = Parameters<
  typeof HovercardDisclosure
>[0];

export type HovercardDismissProps = Parameters<typeof HovercardDismiss>[0];

export type HovercardHeadingProps = Parameters<typeof HovercardHeading>[0];

export type HovercardProviderProps = Parameters<typeof HovercardProvider>[0];

export type MenuArrowProps = Parameters<typeof MenuArrow>[0];

export type MenuBarProps = Parameters<typeof MenuBar>[0];

export type MenuBarProviderProps = Parameters<typeof MenuBarProvider>[0];

export type MenuButtonProps = Parameters<typeof MenuButton>[0];

export type MenuButtonArrowProps = Parameters<typeof MenuButtonArrow>[0];

export type MenuDescriptionProps = Parameters<typeof MenuDescription>[0];

export type MenuDismissProps = Parameters<typeof MenuDismiss>[0];

export type MenuGroupProps = Parameters<typeof MenuGroup>[0];

export type MenuGroupLabelProps = Parameters<typeof MenuGroupLabel>[0];

export type MenuHeadingProps = Parameters<typeof MenuHeading>[0];

export type MenuItemCheckProps = Parameters<typeof MenuItemCheck>[0];

export type MenuItemCheckboxProps = Parameters<typeof MenuItemCheckbox>[0];

export type MenuItemRadioProps = Parameters<typeof MenuItemRadio>[0];

export type MenuListProps = Parameters<typeof MenuList>[0];

export type MenuProviderProps = Parameters<typeof MenuProvider>[0];

export type MenuSeparatorProps = Parameters<typeof MenuSeparator>[0];

export type MenubarProps = Parameters<typeof Menubar>[0];

export type MenubarProviderProps = Parameters<typeof MenubarProvider>[0];

export type PopoverProps = Parameters<typeof Popover>[0];

export type PopoverAnchorProps = Parameters<typeof PopoverAnchor>[0];

export type PopoverArrowProps = Parameters<typeof PopoverArrow>[0];

export type PopoverDescriptionProps = Parameters<typeof PopoverDescription>[0];

export type PopoverDisclosureProps = Parameters<typeof PopoverDisclosure>[0];

export type PopoverDisclosureArrowProps = Parameters<
  typeof PopoverDisclosureArrow
>[0];

export type PopoverDismissProps = Parameters<typeof PopoverDismiss>[0];

export type PopoverHeadingProps = Parameters<typeof PopoverHeading>[0];

export type PopoverProviderProps = Parameters<typeof PopoverProvider>[0];

export type PortalProps = Parameters<typeof Portal>[0];

export type RadioProps = Parameters<typeof Radio>[0];

export type RadioGroupProps = Parameters<typeof RadioGroup>[0];

export type RadioProviderProps = Parameters<typeof RadioProvider>[0];

export type RoleProps = Parameters<typeof Role>[0];

export type SelectProps = Parameters<typeof Select>[0];

export type SelectArrowProps = Parameters<typeof SelectArrow>[0];

export type SelectDismissProps = Parameters<typeof SelectDismiss>[0];

export type SelectGroupProps = Parameters<typeof SelectGroup>[0];

export type SelectGroupLabelProps = Parameters<typeof SelectGroupLabel>[0];

export type SelectHeadingProps = Parameters<typeof SelectHeading>[0];

export type SelectItemProps = Parameters<typeof SelectItem>[0];

export type SelectItemCheckProps = Parameters<typeof SelectItemCheck>[0];

export type SelectLabelProps = Parameters<typeof SelectLabel>[0];

export type SelectListProps = Parameters<typeof SelectList>[0];

export type SelectPopoverProps = Parameters<typeof SelectPopover>[0];

export type SelectProviderProps = Parameters<typeof SelectProvider>[0];

export type SelectRowProps = Parameters<typeof SelectRow>[0];

export type SelectSeparatorProps = Parameters<typeof SelectSeparator>[0];

export type SelectValueProps = Parameters<typeof SelectValue>[0];

export type SeparatorProps = Parameters<typeof Separator>[0];

export type TabProps = Parameters<typeof Tab>[0];

export type TabListProps = Parameters<typeof TabList>[0];

export type TabPanelProps = Parameters<typeof TabPanel>[0];

export type TabProviderProps = Parameters<typeof TabProvider>[0];

export type ToolbarProps = Parameters<typeof Toolbar>[0];

export type ToolbarContainerProps = Parameters<typeof ToolbarContainer>[0];

export type ToolbarInputProps = Parameters<typeof ToolbarInput>[0];

export type ToolbarItemProps = Parameters<typeof ToolbarItem>[0];

export type ToolbarProviderProps = Parameters<typeof ToolbarProvider>[0];

export type ToolbarSeparatorProps = Parameters<typeof ToolbarSeparator>[0];

export type TooltipProps = Parameters<typeof Tooltip>[0];

export type TooltipAnchorProps = Parameters<typeof TooltipAnchor>[0];

export type TooltipArrowProps = Parameters<typeof TooltipArrow>[0];

export type TooltipProviderProps = Parameters<typeof TooltipProvider>[0];

export type VisuallyHiddenProps = Parameters<typeof VisuallyHidden>[0];
