import { styled } from '@linaria/react';
import { cx } from '@zazcart/commons';
import { Heading, Link } from '@zazcart/ui';
import React from 'react';
import { ImageLoader } from '~/components/ImageLoader.tsx';
import { Price } from '~/components/price.tsx';
import {
  ParseCartItemSnapshotConfig,
  parseCartItemSnapshot,
} from '~/components/product-list/parseCartItemSnapshot.tsx';

const ProductItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: var(--min-product-title-width);
  padding-bottom: 6px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  > a {
    width: 100%;
  }
`;

const FeaturedImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 120%;
  background-color: var(--color-border-container);
  border-radius: var(--gap);
  filter: brightness(0.99);
  transition: all 200ms;

  &.mock {
    animation: pulse 1000ms infinite;
  }

  &.reduced {
    padding-top: 100%;
  }

  &:hover {
    filter: brightness(1);
  }

  .FeaturedImage {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    object-fit: cover;
  }
`;

const TitlePriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: var(--gap-05);
  padding-bottom: 0;
  border-radius: 0 0 var(--gap) var(--gap);
  align-items: self-start;
  min-width: var(--min-product-title-width);
  gap: 5px;

  h2,
  h2.Heading {
    font-weight: normal;
    font-size: var(--font-size-200);
    margin-bottom: 5px;
    @media screen and (min-width: 1300px) {
      min-height: 25px;
    }
  }

  .Price {
    line-height: 1;
    margin-top: -8px;
    font-size: 17px;
    font-family: var(--font-family-alt), serif;
  }
`;

export const ProductsGrid = styled.div`
  width: 100%;
  display: grid;
  column-gap: var(--gap);
  row-gap: var(--gap);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 0;

  @media screen and (min-width: 1300px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    padding: 0;
  }
`;

export type ProductCardProps = ParseCartItemSnapshotConfig & {
  className?: string;
  reduced?: boolean;
};

export function ProductCard(props: ProductCardProps) {
  const { reduced, className } = props;
  const { image, link, price, variantTitle } = parseCartItemSnapshot(props);

  return (
    <ProductItem
      className={cx(className)}
      title={variantTitle}
      aria-label={variantTitle}
    >
      <Container>
        <Link
          href={link}
          className={cx({ reduced })}
          accessibilityLabel={`Go to ${variantTitle}`}
        >
          <FeaturedImageWrapper className={cx({ reduced })}>
            <ImageLoader
              fit={'cover'}
              loading={'lazy'}
              alt={variantTitle}
              title={variantTitle}
              image={image}
              className="FeaturedImage"
            />
          </FeaturedImageWrapper>
        </Link>

        <TitlePriceWrapper>
          <Heading level={2} lineClamp={3}>
            {variantTitle}
          </Heading>
          {!reduced && <Price value={price} />}
        </TitlePriceWrapper>
      </Container>
    </ProductItem>
  );
}

export function ProductCardMock() {
  return (
    <ProductItem>
      <Container>
        <FeaturedImageWrapper className={'mock'}></FeaturedImageWrapper>
      </Container>
    </ProductItem>
  );
}
