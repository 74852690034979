import React from 'react';

export function SidebarRight() {
  return (
    <div className={'SidebarWrapper'}>
      <div className={'SidebarContainer'}>
        <div className={'SidebarHeader'} />
        <div className={'SidebarBody'} />
      </div>
    </div>
  );
}
