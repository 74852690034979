import { isBrowser } from 'powership';

export type ReduxDevTools = {
  send(event: { type: string; [K: string]: any }, state: object | null): void;
  init: <S extends object>(state: S, liftedData?: any) => void;
  subscribe: (listener: (message: any) => void) => (() => void) | undefined;
  unsubscribe: () => void;
  error: (payload: string) => void;
};

export type DevToolsConfig<State = any> = {
  field: string;
  initialState: State;
  onChange?(state: State): void;
};

let APP_STATE = Object.create(null);

let appDevTools: ReduxDevTools;

export function connectReduxDevTools(
  config: DevToolsConfig,
): Pick<ReduxDevTools, 'send'> | null {
  if (!isBrowser()) return null;
  if (!window.__REDUX_DEVTOOLS_EXTENSION__) return null;

  let { initialState, field, onChange } = config;

  if (!field.startsWith('@')) {
    field = `@${field}`;
  }

  const devTools =
    appDevTools ||
    window.__REDUX_DEVTOOLS_EXTENSION__.connect({
      name: 'ZAZCART',
    });

  APP_STATE[field] = initialState;

  if (!appDevTools) {
    appDevTools = devTools;
    devTools.init(APP_STATE);
  }

  devTools.subscribe((event) => {
    const payload = event.payload || event;
    if (payload.type !== 'JUMP_TO_ACTION') return;
    onChange?.(JSON.parse(event.state[field]));
  });

  return {
    send({ type, ...event }, state) {
      if (state) {
        APP_STATE[field] = state;
      }
      devTools.send({ type: `${field}/${type}`, ...event }, APP_STATE);
    },
  };
}
