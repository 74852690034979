import './sidebar.scss';

import { cx, useMatchRoute } from '@zazcart/commons';
import { Link, LinkProps, MergeProps } from '@zazcart/ui';
import * as React from 'react';

import {
  Dog,
  Drop,
  HandbagSimple,
  House,
  SignIn,
  Sparkle,
  TShirt,
  type Icon,
} from '@phosphor-icons/react';
import { AppFooter } from '~/components/app-footer';
import { Logo } from '~/components/logo.tsx';
import { useAccount } from '~/lib/accounts/ui';

export function SidebarDefault(props: { onClickLink?: LinkProps['onClick'] }) {
  const { onClickLink } = props;
  const { account, logout } = useAccount();
  const matchRoute = useMatchRoute();

  return (
    <div className="SidebarDefault">
      <div className="sidebar-container">
        <div className="sidebar-header">
          <Link
            href="/"
            className="header-logo"
            accessibilityLabel="Go to home"
          >
            <Logo />
          </Link>
        </div>

        <div className="sidebar-body">
          <SideLink
            href="/"
            label="Home"
            accessibilityLabel="Home"
            icon={House}
            onClick={onClickLink}
            active={matchRoute('/')}
          />

          <SideLink
            href="/collections/joias"
            label="Jóias"
            accessibilityLabel="Jóias"
            icon={Sparkle}
            onClick={onClickLink}
            active={matchRoute('/collections/joias')}
          />

          <SideLink
            href="/collections/beleza-e-perfume"
            label="Beleza e Perfume"
            accessibilityLabel="Beleza e Perfume"
            icon={Drop}
            onClick={onClickLink}
            active={matchRoute('/collections/beleza-e-perfume')}
          />

          <SideLink
            href="/collections/bolsas-e-carteiras"
            label="Bolsas e Carteiras"
            accessibilityLabel="Bolsas e carteiras"
            icon={HandbagSimple}
            onClick={onClickLink}
            active={matchRoute('/collections/bolsas-e-carteiras')}
          />

          <SideLink
            href="/collections/vestuario"
            label="Vestuário"
            accessibilityLabel="Vestuário"
            icon={TShirt}
            onClick={onClickLink}
            active={matchRoute('/collections/vestuario')}
          />

          <SideLink
            href="/collections/pets"
            label="Pets"
            accessibilityLabel="Pets"
            icon={Dog}
            onClick={onClickLink}
            active={matchRoute('/pets')}
          />

          <SideLink
            href="/login"
            label="Entrar"
            accessibilityLabel="Minha conta/Login"
            icon={SignIn}
            onClick={onClickLink}
            active={matchRoute('/login')}
          />
        </div>

        <div className="sidebar-footer">
          <AppFooter />
        </div>
      </div>
    </div>
  );
}

type SidebarLink = MergeProps<
  LinkProps,
  { icon: Icon; label: string; active?: boolean }
>;

function SideLink(props: SidebarLink) {
  const { active, icon, href, label } = props;
  return (
    <Link
      accessibilityLabel={label}
      href={href}
      className={cx('side-link', { active })}
    >
      <div className={'icon-wrapper'}>
        {React.createElement(icon, {
          size: 24,
          weight: active ? 'duotone' : 'regular',
        })}
      </div>
      <div className={'link-label'}>{label}</div>
    </Link>
  );
}
