import './CartRows.scss';

import { cx, ElementProps } from '@zazcart/commons';
import { Link } from '@zazcart/ui';
import * as React from 'react';
import { ReactNode } from 'react';
import { ImageLoader } from '~/components/ImageLoader.tsx';
import { Price } from '~/components/price.tsx';
import { parseCartItemSnapshot } from '~/components/product-list/parseCartItemSnapshot.tsx';
import type { CartItemSnapshot } from '~/entity/cart.ts';
import { DEFAULT_OPTION } from '~/lib/constants.ts';
import { CartStateItem } from '~/state';

export type ProductRowProps = ElementProps<
  { cartItem: CartStateItem; actions?: ReactNode },
  'div'
>;

export function CartRow(props: ProductRowProps) {
  const { className, cartItem, actions } = props;

  const { quantity, image, link, price, productTitle, selectedOptions } =
    parseCartItemSnapshot({
      cartItem,
      product: cartItem.product,
    });

  return (
    <article
      className={cx('CartRow', className)}
      itemScope
      itemType="https://schema.org/Product"
    >
      <div className="cartProduct">
        <Link
          className="image-link"
          href={link}
          accessibilityLabel={productTitle}
        >
          <ImageLoader
            fit={'cover'}
            image={image}
            alt={cartItem.fullTitle}
            itemProp="image"
          />
        </Link>

        <div className="details">
          <h2 itemProp="name">
            {quantity} {productTitle}
          </h2>

          <div className="variant-options">
            {selectedOptions.map((option, idx) => {
              const { name, value } = option;
              const ignoreText = DEFAULT_OPTION === value;
              return (
                <div
                  className="variant-tag"
                  key={`${name}${value}${idx}`}
                  itemProp="additionalProperty"
                  itemScope
                  itemType="https://schema.org/PropertyValue"
                >
                  {ignoreText ? null : (
                    <>
                      <span className={'k'} itemProp="name">
                        {name}
                      </span>
                      :{' '}
                      <span className={'v'} itemProp="value">
                        {value}
                      </span>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
            <meta itemProp="priceCurrency" content="BRL" />
            <Price value={price} itemProp="price" />
          </div>
        </div>
      </div>

      {!!actions && <div className="actions">{actions}</div>}
    </article>
  );
}

export function CartRowBase(props: {
  snapshot: CartItemSnapshot;
  className?: string;
  actions?: ReactNode;
}) {
  const { snapshot, className, actions } = props;
  const {
    link,
    image,
    price,
    quantity,
    productTitle,
    selectedOptions,
    variantTitle,
  } = snapshot;

  return (
    <article
      className={cx('CartRow', className)}
      itemScope
      itemType="https://schema.org/Product"
    >
      <div className="cartProduct">
        <Link
          className="image-link"
          href={link}
          accessibilityLabel={variantTitle}
        >
          <ImageLoader
            fit={'cover'}
            image={image}
            alt={variantTitle}
            itemProp="image"
          />
        </Link>

        <div className="details">
          <h2 itemProp="name">
            {quantity} {productTitle}
          </h2>

          <div className="variant-options">
            {selectedOptions.map((option, idx) => {
              const { name, value } = option;
              const ignoreText = DEFAULT_OPTION === value;
              return (
                <div
                  className="variant-tag"
                  key={`${name}${value}${idx}`}
                  itemProp="additionalProperty"
                  itemScope
                  itemType="https://schema.org/PropertyValue"
                >
                  {ignoreText ? null : (
                    <>
                      <span className={'k'} itemProp="name">
                        {name}
                      </span>
                      :{' '}
                      <span className={'v'} itemProp="value">
                        {value}
                      </span>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
            <meta itemProp="priceCurrency" content="BRL" />
            <Price value={price} itemProp="price" />
          </div>
        </div>
      </div>

      {!!actions && <div className="actions">{actions}</div>}
    </article>
  );
}
