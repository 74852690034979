import { IS_BROWSER, nonNullValues, watchable } from 'powership';
// eslint-disable-next-line import/no-unresolved
import { PRODUCTION } from '@zazcart/commons';
import { CookieSetOptions } from 'universal-cookie';
import { ExpressRequest } from '~/core/interfaces.ts';

export type AppConfig = ReturnType<typeof _getServerConfig>;
export type IsomorphicAppConfig = ReturnType<typeof sharedAppConfig>;

let config: AppConfig;

export function getAppConfig() {
  return (config =
    config ||
    config ||
    // @onlyServer
    _getServerConfig() ||
    sharedAppConfig());
}

// frontend readable config
export function sharedAppConfig() {
  let {
    COMPANY_NAME,
    SITE_NAME,
    NODE_ENV,
    DEV = NODE_ENV === 'development',
    HARD_LIMIT_QUANTITY = '999',
    STRIPE_PUBLIC_KEY,
    CLOUDFLARE_TURNSTILE_PUBLIC_KEY,
    MERCADOPAGO_PUBLIC_KEY,
  } = IS_BROWSER ? (window as any).INSTATE.appConfig : process.env;

  const PROD = !DEV;

  return nonNullValues({
    STRIPE_PUBLIC_KEY,
    HARD_LIMIT_QUANTITY: +HARD_LIMIT_QUANTITY,
    COMPANY_NAME,
    SITE_NAME,
    DEV,
    PROD,
    CLOUDFLARE_TURNSTILE_PUBLIC_KEY,
    MERCADOPAGO_PUBLIC_KEY,
  });
}

// @onlyServer
function _getServerConfig() {
  let {
    COMPANY_NAME,
    SITE_NAME,
    SHOPIFY_STORE_DOMAIN,
    SHOPIFY_REVALIDATION_SECRET,
    SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    SK_SEC,
    STRIPE_PUBLIC_KEY,
    SPA_KEY,
    CEP,
    CORREIOS,
    KANGU_KEY,
    MONGO_URL,
    MELHOR_ENVIO,
    PAYPAL_CLIENT_ID,
    PAYPAL_CLIENT_SECRET,
    PAYPAL_CHECKOUT_URL,
    PAG_SEGURO_URL,
    PAG_SEGURO_URL_PIX,
    PAG_SEGURO_TOKEN,
    TWILIO_TOKEN,
    TWILIO_SID,
    TWILIO_VERIFY_SERVICE_ID,
    SMTP_SERVER,
    SMTP_USER,
    SMTP_PORT,
    SMTP_KEY,
    SENDGRID_TOKEN,
    NODE_ENV = 'production',
    URL_SIGN_TOKEN,
    CLOUDFLARE_TURNSTILE_SECRET,
    MERCADOPAGO_TOKEN,
  } = process.env;

  const DEV = NODE_ENV === 'development';

  const sharedConfig = sharedAppConfig();

  const SHOPIFY_GRAPHQL_API_ENDPOINT = '/api/2024-07/graphql.json';

  SHOPIFY_STORE_DOMAIN = SHOPIFY_STORE_DOMAIN
    ? ensureStartsWith(SHOPIFY_STORE_DOMAIN, 'https://')
    : '';

  const SHOPIFY_API_URL = `${SHOPIFY_STORE_DOMAIN}${SHOPIFY_GRAPHQL_API_ENDPOINT}`;

  const requiredEnvironmentVariables = nonNullValues({
    ...sharedConfig,
    sharedConfig,
    MONGO_URL,
    URL_SIGN_TOKEN,
    SHOPIFY_API_URL,
    COMPANY_NAME,
    SITE_NAME,
    SHOPIFY_STORE_DOMAIN,
    SHOPIFY_REVALIDATION_SECRET,
    SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    SK_SEC,
    STRIPE_PUBLIC_KEY,
    SPA_KEY,
    CEP,
    CORREIOS,
    KANGU_KEY,
    NODE_ENV,
    DEV,
    MELHOR_ENVIO,
    PAYPAL_CLIENT_ID,
    PAYPAL_CLIENT_SECRET,
    PAYPAL_CHECKOUT_URL,
    PAG_SEGURO_URL,
    PAG_SEGURO_URL_PIX,
    PAG_SEGURO_TOKEN,
    TWILIO_TOKEN,
    TWILIO_SID,
    TWILIO_VERIFY_SERVICE_ID,
    SMTP_SERVER,
    SMTP_USER,
    SMTP_PORT,
    SMTP_KEY,
    SENDGRID_TOKEN,
    CLOUDFLARE_TURNSTILE_SECRET,
    MERCADOPAGO_TOKEN,
  });

  const missingEnvironmentVariables = Object.entries(
    requiredEnvironmentVariables,
  ).filter(([k, v]) => {
    return v !== undefined ? null : k;
  });

  if (missingEnvironmentVariables.length) {
    throw new Error(
      `The following environment variables are missing. ${missingEnvironmentVariables}'\n'
      )}\n`,
    );
  }

  if (
    SHOPIFY_STORE_DOMAIN?.includes('[') ||
    SHOPIFY_STORE_DOMAIN?.includes(']')
  ) {
    throw new Error(
      'Your `SHOPIFY_STORE_DOMAIN` environment variable includes brackets (ie. `[` and / or `]`). Your site will not work with them there. Please remove them.',
    );
  }

  return requiredEnvironmentVariables;
}

export const appConfig = watchable(() => getAppConfig());

const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith)
    ? stringToCheck
    : `${startsWith}${stringToCheck}`;

export const DOMAINS = [
  'zazcart.com', // default
  'zazcart.com.br',
  'zazcard.com.br',
  'localhost',
  'supermart.com.br',
];

export const DEFAULT_DOMAIN = DOMAINS[0];

export type RuntimeAppSettings = {
  cookieOptions: CookieSetOptions;
  sanitizedDomain: string;
  sanitizedSiteURL: string;
  shouldBeHTTPS: boolean;
  shouldRedirectToHTTPS: boolean;
  csrfToken?: string;
  userAgent?: string;
  clientIp?: string;
  isBot: boolean;
  environment: 'development' | 'production';
};

export function runtimeAppContextSettings(
  request: ExpressRequest,
): RuntimeAppSettings {
  const { headers, secure } = request;
  const { DEV } = appConfig;

  const sanitizedDomain = (() => {
    if (DEV && (headers.origin || headers.referer || '').match(/localhost/)) {
      return 'localhost';
    }
    let fromHeader = DOMAINS.find((el) => headers.origin?.endsWith(el));
    if (fromHeader) return fromHeader;
    fromHeader = DOMAINS.find((el) => headers.referer?.endsWith(el));
    return fromHeader || DEFAULT_DOMAIN;
  })();

  const shouldBeHTTPS = PRODUCTION || secure;

  const sanitizedSiteURL = `${shouldBeHTTPS ? 'https://' : 'http://'}${sanitizedDomain}`;

  const cookieOptions: CookieSetOptions = {
    domain: sanitizedDomain === 'localhost' ? undefined : `.${sanitizedDomain}`,
    httpOnly: true,
    path: '/',
    sameSite: 'lax',
    secure: shouldBeHTTPS,
  };

  const csrfToken = headers['x-csrf-token'] as string | undefined;

  return {
    cookieOptions,
    sanitizedDomain,
    sanitizedSiteURL,
    shouldBeHTTPS,
    shouldRedirectToHTTPS: shouldBeHTTPS && !secure,
    csrfToken,
    userAgent: headers['user-agent'],
    clientIp: (headers['x-forwarded-for'] || headers['x-real-ip'])?.toString(),
    isBot: /bot|crawler|spider|crawling/i.test(headers['user-agent'] || ''),
    environment: DEV ? 'development' : 'production',
  };
}
