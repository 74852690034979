import { invariant } from 'powership';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

export type UIContextValue = {
  threads: Threads;
};

export const UIContext = React.createContext(null as unknown as UIContextValue);

export function useUIContext() {
  const ctx = React.useContext(UIContext);
  invariant(ctx, 'UIContext is missing');
  return ctx;
}

export function UIProvider(props: { children: ReactNode }) {
  const { children } = props;

  const value = useMemo<UIContextValue>(() => {
    return {
      threads: createThreads(),
    };
  }, []);

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}

export function useUniqID() {
  const { threads } = useUIContext();
  const [id] = useState(() => threads.alloc());

  const unmountTimeout = useRef<any>();

  useEffect(() => {
    clearTimeout(unmountTimeout.current);
    return () => {
      unmountTimeout.current = setTimeout(() => {
        threads.release(id);
      }, 100);
    };
  }, []);

  return `z${id}`;
}

function createThreads() {
  let nextAvailableThreadIDs = new Uint16Array(16);
  for (let i = 0; i < 15; i++) {
    nextAvailableThreadIDs[i] = i + 1;
  }
  nextAvailableThreadIDs[15] = 0;

  function growThreadCountAndReturnNextAvailable() {
    const oldArray = nextAvailableThreadIDs;
    const oldSize = oldArray.length;
    const newSize = oldSize * 2;
    invariant(
      newSize <= 0x10000,
      'Maximum number of concurrent threads exceeded. ',
    );
    const newArray = new Uint16Array(newSize);
    newArray.set(oldArray);
    nextAvailableThreadIDs = newArray;
    nextAvailableThreadIDs[0] = oldSize + 1;
    for (let i = oldSize; i < newSize - 1; i++) {
      nextAvailableThreadIDs[i] = i + 1;
    }
    nextAvailableThreadIDs[newSize - 1] = 0;
    return oldSize;
  }

  function alloc(): number {
    const nextID = nextAvailableThreadIDs[0];
    if (nextID === 0) {
      return growThreadCountAndReturnNextAvailable();
    }
    nextAvailableThreadIDs[0] = nextAvailableThreadIDs[nextID];
    return nextID;
  }

  function release(id: number) {
    nextAvailableThreadIDs[id] = nextAvailableThreadIDs[0];
    nextAvailableThreadIDs[0] = id;
  }

  return { alloc, release };
}

export type Threads = ReturnType<typeof createThreads>;
