import { Process } from 'powership';

// @onlyServer
import tracer from 'tracer';

const { LOG_LEVEL = 'info' } = Process.env;

export type LogLevel =
  | 'log'
  | 'trace'
  | 'debug'
  | 'info'
  | 'warn'
  | 'error'
  | 'fatal';

const methods = ['error', 'warn', 'info', 'debug', 'trace', 'log'] as const;
export type LogMethod = (typeof methods)[number];

// copy from  tracer.Tracer.LogOutput, plus typed 'method'
export type LoggerEvent = {
  method: LogMethod;

  timestamp: string;
  /**
   * The result of formating the template and arguments in `args`.
   */
  message: string;
  /**
   * Method name, default is `log`, `trace`, `debug`, `info`, `warn`, `error`, `fatal`.
   */
  title: string;
  /**
   * Method level, default is `log`: 0, `trace`: 1, `debug`: 2, `info`: 3, `warn`: 4, `error`: 5, `fatal`: 6.
   */
  level: number;
  /**
   * Arguments of `Logger` method.
   */
  args: any[];

  /**
   * File's path.
   */
  path: string;
  /**
   * Line number.
   */
  line: number;
  /**
   * Position.
   */
  pos: number;
  /**
   * folder path.
   */
  folder: string;
  /**
   * File's name.
   */
  file: string;
  /**
   * Call stack message.
   */
  stack: string;
  /**
   * The output to be written
   */
  output: string;
};

export type LoggerSubscriber = (event: LoggerEvent) => void;

export class Logger {
  private subscribers = new Set<LoggerSubscriber>();

  constructor(public level = LOG_LEVEL as LogLevel) {}

  subscribe = (cb: LoggerSubscriber) => {
    this.subscribers.add(cb);
    return () => {
      return this.subscribers.delete(cb);
    };
  };

  private instance = (() => {
    // @onlyServer
    return tracer.colorConsole({
      level: this.level,
      stackIndex: 2,
      transport: (data) => {
        console.log(data.output);
        this.subscribers.forEach((subs) => {
          // @ts-ignore
          subs(data);
        });
      },
    });
  })();

  private dispatch = (method: LogMethod, ...dada: any[]) => {
    // @onlyBrowser
    console[method](...dada);

    // @onlyServer
    this.instance[method](...dada);
  };

  log = (...args: any[]) => {
    return this.dispatch('log', ...args);
  };

  trace = (...args: any[]) => {
    return this.dispatch('trace', ...args);
  };

  debug = (...args: any[]) => {
    return this.dispatch('debug', ...args);
  };

  info = (...args: any[]) => {
    return this.dispatch('info', ...args);
  };

  warn = (...args: any[]) => {
    return this.dispatch('warn', ...args);
  };

  error = (...args: any[]) => {
    return this.dispatch('error', ...args);
  };
}

export const logger = new Logger();
